// Fix: Promise.allSettled is not a function
// https://sentry.io/organizations/drivy/issues/2629089524/?project=167570
// source: https://github.com/amrayn/allsettled-polyfill/blob/master/index.js
if (!Promise.allSettled) {
  Promise.allSettled =
    Promise.allSettled ||
    ((promises) =>
      Promise.all(
        promises.map((p) =>
          p
            .then((value) => ({
              status: "fulfilled",
              value,
            }))
            .catch((reason) => ({
              status: "rejected",
              reason,
            }))
        )
      ))
}
