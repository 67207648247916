import { EventProcessor } from "@sentry/types"

const global = window as Window

const referrerEventProcessor: EventProcessor = (event) => {
  if (!global.document) return event

  const request = event.request || {}
  request.headers = request.headers || {}
  request.headers.Referrer = document.referrer

  event.request = request

  return event
}

export default referrerEventProcessor
