import { queryAll } from "@drivy/dom-query"

export const initImageSwappers = () => {
  const imageSwappers = queryAll(".js_image-swapper[data-duration-in-ms]")

  const IMAGE_SWAPPER_SHOW_CLASS = "image-swapper__image--show"
  const IMAGE_SWAPPER_SHOWING_CLASS = "image-swapper__image--showing"

  imageSwappers.forEach((imageSwapper) => {
    if (imageSwapper.children.length) {
      let currentImageIndex = 0

      imageSwapper.children[currentImageIndex].classList.add(
        IMAGE_SWAPPER_SHOW_CLASS
      )

      setInterval(
        () => {
          const newImageIndex =
            (currentImageIndex + 1) % imageSwapper.children.length
          imageSwapper.children[currentImageIndex].classList.remove(
            IMAGE_SWAPPER_SHOWING_CLASS
          )
          imageSwapper.children[currentImageIndex].classList.add(
            IMAGE_SWAPPER_SHOW_CLASS
          )
          imageSwapper.children[newImageIndex].classList.add(
            IMAGE_SWAPPER_SHOWING_CLASS
          )
          setTimeout(() => {
            imageSwapper.children[currentImageIndex].classList.remove(
              IMAGE_SWAPPER_SHOW_CLASS
            )
            currentImageIndex = newImageIndex
          }, 2000)
        },
        parseInt(imageSwapper.dataset.durationInMs!, 10)
      )
    }
  })
}
