import { toEnhancedHTMLElement, queryStrict, query } from "@drivy/dom-query"
import { notifyError, createError } from "core/errors"
import makeDatetimeInputActive from "drivy_bootstrap/components/day_picker/datetime_range_input/makeDatetimeInputActive"
import unlockForm from "views/web/search/search_hero_form/unlockForm"
import {
  showInputLoadingState,
  hideInputLoadingState,
} from "utils/inputLoadingState"

type ParamsType = {
  onChange?: (addres: any, datetimeRangeInput: any) => void
  afterInitialize?: () => void
  loadingError: ReturnType<typeof createError>
  formToUnlock?: ReturnType<typeof query>
  triggerer: ReturnType<typeof queryStrict>
}

const initAsyncSearchForm = ({
  onChange,
  afterInitialize,
  loadingError,
  formToUnlock,
  triggerer,
}: ParamsType) => {
  const isAddressSelectorElement = (element: unknown) => {
    if (!(element instanceof HTMLElement)) return false
    return element.classList.contains("js_address_autocomplete")
  }

  async function loadReactForm(target?: unknown) {
    if (target instanceof HTMLElement) {
      if (target.classList.contains("js_date_input")) {
        showInputLoadingState(toEnhancedHTMLElement(target))
        makeDatetimeInputActive(target)
      } else if (isAddressSelectorElement(target)) {
        showInputLoadingState(toEnhancedHTMLElement(target))
      }
    }

    try {
      const [{ SearchHeroDatetimeRangeInput }, { initAddressAutocomplete }] =
        await Promise.all([
          import("views/web/search/search_hero_form"),
          import("components/address_autocomplete"),
        ])

      const datetimeRangeInput = SearchHeroDatetimeRangeInput()

      initAddressAutocomplete(".js_address_autocomplete", {
        history: !!query(".js_address_autocomplete")?.dataset.searchHistory,
        searchOnInit: isAddressSelectorElement(target),
        afterInitialize: () => {
          afterInitialize && afterInitialize()

          if (target instanceof HTMLElement) {
            hideInputLoadingState(toEnhancedHTMLElement(target))
            target.click()
          }
        },
        onChange: (address) => {
          onChange && onChange(address, datetimeRangeInput)
        },
      })
    } catch (err) {
      notifyError(new loadingError(err.message))
    }
  }

  // If the user is already in the field when loading this script
  if (
    document.activeElement &&
    isAddressSelectorElement(document.activeElement)
  ) {
    loadReactForm(document.activeElement)
  } else {
    triggerer.addEventListener(
      "click",
      (e) => {
        loadReactForm(e?.target)
      },
      { once: true }
    )
  }

  formToUnlock && unlockForm(formToUnlock)
}

export default initAsyncSearchForm
