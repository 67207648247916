import { debounce } from "utils/function"
import { getScrollbarWidth, scrollToLeft } from "utils/dom"

const SCROLLABLE_LEFT_CLASS = "horizontal_scroll_list--scrollable-left"
const SCROLLABLE_RIGHT_CLASS = "horizontal_scroll_list--scrollable-right"
const ENHANCED_CLASS = "horizontal_scroll_list--enhanced"

export default (list) => {
  const inner = list.querySelector(".js_horizontal_scroll_list_inner")

  const leftGradient = list.querySelector(
    ".js_horizontal_scroll_list_gradient_left"
  )
  const rightGradient = list.querySelector(
    ".js_horizontal_scroll_list_gradient_right"
  )

  const applyGradient = ({ scrollPosition, gradientEl, modifierClass }) => {
    if (!gradientEl) return

    if (scrollPosition > 0) {
      list.classList.add(modifierClass)
      gradientEl.style.opacity =
        Math.min(scrollPosition, gradientEl.offsetWidth) /
        gradientEl.offsetWidth
    } else {
      list.classList.remove(modifierClass)
    }
  }

  const applyGradients = () => {
    const { scrollLeft, scrollWidth, clientWidth } = inner
    const scrollRight = scrollWidth - clientWidth - scrollLeft

    applyGradient({
      scrollPosition: scrollLeft,
      gradientEl: leftGradient,
      modifierClass: SCROLLABLE_LEFT_CLASS,
    })

    applyGradient({
      scrollPosition: scrollRight,
      gradientEl: rightGradient,
      modifierClass: SCROLLABLE_RIGHT_CLASS,
    })
  }

  const scroll = (direction) => {
    scrollToLeft(
      inner,
      inner.scrollLeft + (direction * inner.clientWidth) / 2,
      200
    ).then(applyGradients)
  }

  const computeHeight = () => {
    list.classList.remove(ENHANCED_CLASS)
    list.style.height = null
    inner.style.height = null

    const height = list.offsetHeight

    list.style.height = `${height}px`
    // default to 20 when we can't detect (e.g. macOS not fixed scrollbars)
    inner.style.height = `${height + (getScrollbarWidth() || 20)}px`

    list.classList.add(ENHANCED_CLASS)
  }

  list.querySelectorAll("[data-trigger-scroll]").forEach((trigger) => {
    const direction = parseInt(trigger.dataset.triggerScroll, 10)
    trigger.addEventListener("click", scroll.bind(null, direction))
  })

  inner.addEventListener("scroll", debounce(applyGradients, 10))

  window.addEventListener("resize", debounce(computeHeight, 50))

  computeHeight()
  applyGradients()

  return { computeHeight }
}
