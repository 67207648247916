import { query } from "@drivy/dom-query"
import config from "../config"
import { COOKIE_CONSENT_WRAPPER_CLASS } from "./cookies"
import handleAsyncImportError from "core/handleAsyncImportError"
import { createError } from "core/errors"

const initCookieConsentIfneeded = async (afterInit?: () => void) => {
  if (
    config.trackingEnabled &&
    query(COOKIE_CONSENT_WRAPPER_CLASS)?.dataset.displayCookieConsent === "true"
  ) {
    const initCookieConsentModal = await handleAsyncImportError(
      async () => (await import("./init")).default,
      createError("CookieConsentAsyncInitError")
    )
    initCookieConsentModal?.(afterInit)
  } else {
    afterInit?.()
  }
}

export default initCookieConsentIfneeded
