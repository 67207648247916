import { EnhancedHTMLElement, query } from "@drivy/dom-query"
import tracking from "core/tracking"

const NEWS_TRACKING_EVENT_NAME = "homepage_news_section_article_clicked"

function initLinkTracking(link: HTMLAnchorElement & EnhancedHTMLElement) {
  link.on("click", () => {
    tracking.event(NEWS_TRACKING_EVENT_NAME, {
      article_url: link.href,
    })
  })
}

export function initNewsSnippets() {
  const newsSnippets = query(".js_news-snippets")
  if (newsSnippets) {
    newsSnippets.queryAll<HTMLAnchorElement>("a").forEach(initLinkTracking)
  }
}
