import { query, queryAll, queryStrict } from "@drivy/dom-query"
import { fetchGetOptions } from "api/fetch"
import { notifyError, createError } from "core/errors"
import { keysToCamel } from "utils/object"
import { UserMenuAPIRawType, CssClassesType } from "./models"
import { onAuthenticatedUser } from "./utils"

const UserMenuError = createError("UserMenuError")
const UserMenuAsyncImportError = createError("UserMenuAsyncImportError")
const UserMenuAPIParsingError = createError("UserMenuAPIParsingError")

const getUserMenuMount = async () => {
  try {
    return (await import("./mount")).default
  } catch (err) {
    notifyError(new UserMenuAsyncImportError(err.message))
    return null
  }
}

async function getUserMenuData() {
  let response: Response
  try {
    response = await fetch(
      `/api/internal/user_menu?current_path=${window.location.pathname}`,
      {
        ...fetchGetOptions,
        credentials: "include",
      }
    )
  } catch (err) {
    // we ignore network errors
    return null
  }
  if (!response.ok) {
    throw new UserMenuError(`${response.status}: ${response.statusText}`)
  } else {
    try {
      return (await response.json()) as UserMenuAPIRawType
    } catch (err) {
      notifyError(new UserMenuAPIParsingError(err.message))
      return null
    }
  }
}

const initUserMenu = async () => {
  const placeholder = query(".js_user_menu_placeholder")
  if (!placeholder) return
  const cssClasses = placeholder.dataset.classes
    ? (keysToCamel(JSON.parse(placeholder.dataset.classes)) as CssClassesType)
    : undefined

  try {
    const userMenuData = await getUserMenuData()
    if (!userMenuData) return
    if (!userMenuData.user_infos) {
      // user not logged in
      const logoutElements = queryAll(".js_logout-only")
      logoutElements.forEach((e) => e.classList.remove("c-hidden"))

      const userMenuButton = query(".js_user_menu_trigger")
      if (userMenuButton) {
        userMenuButton.addEventListener(
          "click",
          async () => {
            // loading state
            queryStrict(".js_user-menu-icon").classList.add("c-hidden")
            queryStrict(".js_user-menu-icon--loading").classList.remove(
              "c-hidden"
            )

            const mountUserMenu = await getUserMenuMount()

            mountUserMenu &&
              mountUserMenu({
                placeholder,
                links: userMenuData.links,
                cssClasses,
                defaultOpened: true,
              })
          },
          { once: true }
        )
      }
    } else {
      onAuthenticatedUser(userMenuData.user_infos)

      const mountUserMenu = await getUserMenuMount()

      mountUserMenu &&
        mountUserMenu({
          placeholder,
          user: userMenuData.user_infos,
          links: userMenuData.links,
          cssClasses,
        })
    }
  } catch (err) {
    notifyError(new UserMenuError(err.message))
  }
}

export default initUserMenu
