export function unobfuscate(str: string) {
  return str
    .match(/.{1,2}/g)
    ?.reverse()
    .map((chunk: string) => String.fromCharCode(parseInt(chunk, 36)))
    .join("")
}

export default (el) => {
  const link = document.createElement("a")
  link.innerHTML = el.innerHTML
  link.className = el.className
  link.classList.remove("js_drk_lnk")
  link.setAttribute("href", unobfuscate(el.getAttribute("data-value")) || "")
  link.setAttribute("rel", "nofollow")

  if (!(el.getAttribute("data-noblank") === "true")) {
    link.setAttribute("target", "_blank")
  }

  if (el.getAttribute("data-title")) {
    link.setAttribute("title", el.getAttribute("data-title"))
  }

  el.parentNode.replaceChild(link, el)
}
