import config from "core/config"
import tracking from "core/tracking"

const WELCOME_TO_GETAROUND_US_HASH = "#welcome-to-florida"
export const TRACKING_EVENT_HOMEPAGE = "us-florida-redirection"
export const TRACKING_EVENT_SEARCH = "us-florida-redirection"
export const TRACKING_EVENT_CAR_SHOW = "us-florida-redirection-car-show"

export const LEARN_MORE_URL = "/migration-2023"

function isWelcomeToGetaroundUS() {
  return (
    config.country === "US" &&
    window.location.hash === WELCOME_TO_GETAROUND_US_HASH
  )
}

export async function onWelcomeToGetaroundUS(
  trackingEvent: string,
  callback: () => void
) {
  if (isWelcomeToGetaroundUS()) {
    window.history.replaceState(
      null,
      document.title,
      window.location.pathname + window.location.search
    )

    tracking.event(trackingEvent)

    callback()
  }
}
