import { EventProcessor } from "@sentry/types"

interface CustomError extends Error {
  fingerprint: string[]
}

const customErrorFingerprintEventProcessor: EventProcessor = (event, hint) => {
  if (hint && hint.originalException) {
    const originalException = hint.originalException as CustomError

    if (originalException.fingerprint)
      event.fingerprint = originalException.fingerprint
  }

  return event
}

export default customErrorFingerprintEventProcessor
