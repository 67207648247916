export const compact = (array: any[]): any[] => array.filter((value) => value)

export const uniq = (array: any[]): any[] => Array.from(new Set(array))

export const range = (start: number, end: number): number[] =>
  Array.from(Array(end - start).keys()).map((i) => i + start)

export const intersection = (a: any[], b: any[]): any[] => {
  const bSet = new Set(b)

  return Array.from(new Set(a.filter((v) => bSet.has(v))))
}

const moveMutate = (array: any[], from: number, to: number) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])
}

export const move = (array: any[], from: number, to: number) => {
  const copy = array.slice()
  moveMutate(copy, from, to)
  return copy
}

export const getLastItem = <T>(array: T[]) => array[array.length - 1]

export const getUniqueObjects = <TObject extends Record<string, any>>(
  arr: TObject[],
  prop: keyof TObject
): TObject[] => {
  return arr
    .map((e) => e[prop])
    .map((e, i, array) => array.indexOf(e) === i)
    .filter((e) => e)
    .map((_e, i) => arr[i])
}
// Transforms a list like ["foo", "bar", "baz"] into an object like { foo: true, bar: true, baz: true }
export const getBooleansObjectFromKeysList = <T extends readonly string[]>(
  keys: T,
  value = true
) =>
  keys.reduce(
    (acc, key) => ({ ...acc, [key]: value }),
    {} as { [key in T[number]]: boolean }
  )
