import { initFacebookPixel } from "./thirdPartyTracking/facebookPixel"
import {
  initGoogleAdsBeforeConsent,
  setGoogleAdsConsent,
} from "./thirdPartyTracking/googleAds"
import capturTracking, { init as initCapturTracking } from "./capturTracking"
import { isCookieConsentGivenFor } from "core/cookieConsent/cookies"
import { initHotjar } from "./thirdPartyTracking/hotjar"
import { initAdmoTV } from "./thirdPartyTracking/admoTV"
import { initTiktok } from "./thirdPartyTracking/tiktok"
import { initSnapchat } from "./thirdPartyTracking/snapchat"
import { initCriteo } from "./thirdPartyTracking/criteo"
import { initBing } from "./thirdPartyTracking/bing"
import { initAdjust } from "./thirdPartyTracking/adjust"

export type { Properties as CapturTrackingPropertiesType } from "./capturTracking"

type initFunc = () => Promise<void>

const loadTrackingsInitScripts = async (initScripts: initFunc[]) => {
  // We can't do nothing about scripts not loaded here since it could just be
  // blocked by the user, so we don't check the promises statuses (rejected or resolved)
  await Promise.allSettled(initScripts.map((f) => f()))
}

export const initTrackingBeforeConsent = async () => {
  initCapturTracking()
  try {
    await initGoogleAdsBeforeConsent()
  } catch (e) {
    // We can't do nothing about scripts not loaded here since it could just be
    // blocked by the user, we just catch the error to not block JS loading
  }
}

export const initThirdPartyTracking = async () => {
  const initScripts: initFunc[] = []
  if (isCookieConsentGivenFor("performance")) {
    initScripts.push(initHotjar)
  }
  if (isCookieConsentGivenFor("targeting")) {
    initScripts.push(
      setGoogleAdsConsent,
      initFacebookPixel,
      initAdmoTV,
      initTiktok,
      initSnapchat,
      initCriteo,
      initBing,
      initAdjust
    )
  }
  try {
    await loadTrackingsInitScripts(initScripts)
  } catch (e) {
    // We can't do nothing about scripts not loaded here since it could just be
    // blocked by the user, we just catch the error to not block JS loading
  }
}

export const event = capturTracking.event
export const page = capturTracking.page

export default {
  event: capturTracking.event,
  page: capturTracking.page,
}
