import { UserMenuLinkType, UserType } from "./models"

export const filterLinks = (
  linksToFilter: UserMenuLinkType[],
  isOwner: boolean,
  isAsOwner: boolean = isOwner
) => {
  return linksToFilter.filter(
    (link) =>
      !link.access ||
      (link.access === "driver" && !isAsOwner) ||
      (isOwner && isAsOwner && link.access === "owner")
  )
}

export const hasBadge = (links: UserMenuLinkType[]): boolean => {
  return links.filter((link) => link.badge).length > 0
}

export const removeMobileLinks = (links: UserMenuLinkType[]) =>
  links.filter((link) => !link.mobile_only)

export const getPageLinks = (links: UserMenuLinkType[]) =>
  links.filter((link) => !link.special)

export const getSpecialLinks = (links: UserMenuLinkType[]) =>
  links.filter((link) => link.special)

export const getLink = (links: UserMenuLinkType[], linkId: string) =>
  links.find((link) => link.identifier === linkId)

type AuthenticatedUserCallbackType = (userInfos: UserType) => void

const callbacks: AuthenticatedUserCallbackType[] = []

export function onAuthenticatedUser(userInfos: UserType) {
  callbacks.forEach((callback) => {
    callback(userInfos)
  })
}

export function registerOnAuthenticatedUserCallback(
  callback: AuthenticatedUserCallbackType
) {
  callbacks.push(callback)
}
