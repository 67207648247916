import loadRemoteScript from "../../loadRemoteScript"
import config from "core/config"

type AdmoParamsType = {
  action: "page_load"
  label: "recherche" | "inscription" | "reservation"
  value: "1"
}

// Global variables set by AdmoTV script
declare const window: Window &
  typeof globalThis & {
    ADMO_TT: AdmoParamsType[]
    isAdmoTVInitialized: boolean
  }

let loadingPromise: Promise<void>

const trackingEventsQueue: string[] = []

const enqueueEvent = (label) => {
  trackingEventsQueue.push(label)
}

const trackEvent = (label) => {
  window.ADMO_TT = window.ADMO_TT || []
  window.ADMO_TT.push({
    action: "page_load",
    label,
    value: "1",
  })
}

const trackEnqueuedEvents = () => {
  while (trackingEventsQueue.length) {
    trackEvent(trackingEventsQueue.pop()!)
  }
}

export const initAdmoTV = async () => {
  if (!config.isAdmoTVEnabled) return
  if (window.isAdmoTVInitialized) return
  if (loadingPromise) return loadingPromise

  const loadAdmoTV = async () => {
    await loadRemoteScript(
      "https://getaround.script.admo.tv/getaround/script.min.js"
    )
    window.isAdmoTVInitialized = true
    trackEnqueuedEvents()
  }

  loadingPromise = loadAdmoTV()
  return loadingPromise
}

// Sometimes we use the trackConversion method when displaying the page.
// Because we have to wait for the user's consent, the track init methods are called on DOM Loaded.
// Therefore, the trackConversion method is called before the init method.
// In this case, we enqueue the event which will be dequeued to call the track method at the end of the init method.
export const trackConversionWithAdmoTV = async (
  label: AdmoParamsType["label"]
) => {
  window.isAdmoTVInitialized ? trackEvent(label) : enqueueEvent(label)
}
