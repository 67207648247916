// source: https://github.com/knowledgecode/jquery-param/blob/master/src/index.js
export const vanillaParam = function (a) {
  const s: string[] = []
  const add = function (k, v) {
    const newV = typeof v === "function" ? v() : v
    s[s.length] =
      encodeURIComponent(k) +
      "=" +
      encodeURIComponent(newV === null ? "" : newV === undefined ? "" : newV)
  }
  const buildParams = function (prefix, obj) {
    let i, len, key

    if (prefix) {
      if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          buildParams(
            prefix +
              "[" +
              (typeof obj[i] === "object" && obj[i] ? i : "") +
              "]",
            obj[i]
          )
        }
      } else if (String(obj) === "[object Object]") {
        for (key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            buildParams(prefix + "[" + key + "]", obj[key])
          }
        }
      } else {
        add(prefix, obj)
      }
    } else if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value)
      }
    } else {
      for (key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          buildParams(key, obj[key])
        }
      }
    }
    return s
  }

  return buildParams("", a).join("&")
}

const param = (obj: Record<string, unknown>) => {
  if ((window as Window & { $: any } & typeof globalThis).$ && $.param) {
    // eslint-disable-next-line jquery/no-param
    return $.param(obj)
  } else {
    return vanillaParam(obj)
  }
}

export default param
