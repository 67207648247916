import { createError, notifyError } from "./errors"

const handleAsyncImportError = async <T>(
  importer: () => T,
  ErrorInstance: ReturnType<typeof createError> = createError(
    "AsyncImportError"
  )
) => {
  try {
    return await importer()
  } catch (e) {
    notifyError(new ErrorInstance("Async import error: " + e.message))
    return
  }
}

export default handleAsyncImportError
