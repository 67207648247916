import tracking from "core/tracking"
import {
  trackEventWithGoogleAnalytics,
  trackConversionWithGoogleAds,
  trackRemarketingWithGoogleAds,
  trackConversionWithFacebook,
  trackConversionWithAdmoTV,
  trackConversionWithTiktok,
  trackConversionWithSnapchat,
  trackConversionWithCriteo,
  trackConversionWithBing,
  trackConversionWithAdjust,
} from "drivy_bootstrap/utils/tracking"

type ParamType =
  | "name"
  | "params"
  | "page"
  | "event"
  | "label"
  | "value"
  | "currency"
  | "id"
  | "eventToken"

const createHandler =
  (fn, attrs: ParamType[] = []) =>
  (params) =>
    fn(...attrs.map((attr) => params[attr]))

const HANDLERS = {
  page: createHandler(tracking.page, ["name", "params"]),
  event: createHandler(tracking.event, ["name", "params"]),
  googleAnalytics: createHandler(trackEventWithGoogleAnalytics, [
    "event",
    "params",
  ]),
  facebook: createHandler(trackConversionWithFacebook, ["event", "params"]),
  bing: createHandler(trackConversionWithBing, ["event", "params"]),
  googleAds: createHandler(trackConversionWithGoogleAds, [
    "label",
    "value",
    "currency",
  ]),
  googleAdsRemarketing: createHandler(trackRemarketingWithGoogleAds, [
    "params",
  ]),
  admoTV: createHandler(trackConversionWithAdmoTV, ["label"]),
  tiktok: createHandler(trackConversionWithTiktok, ["event", "params"]),
  snapchat: createHandler(trackConversionWithSnapchat, ["event", "params"]),
  criteo: createHandler(trackConversionWithCriteo, ["event", "params"]),
  adjust: createHandler(trackConversionWithAdjust, ["eventToken", "params"]),
}

export default function handleDeferredTracking(data) {
  Object.keys(data).forEach((handlerName) => {
    const handler = HANDLERS[handlerName]

    if (!handler) {
      throw new Error(`Unkown tracking handler "${handlerName}"`)
    }

    data[handlerName].forEach(handler)
  })
}
