import isEmpty from "lodash/isEmpty"
export { isEmpty }
import { toSnakeCase, toCamelCase } from "utils/string"

import delve from "dlv" // eslint-disable-line no-restricted-imports
export { delve }

/**
 * Explaination:
 * - S extends `...`: means if S is the same type of `...` which is here a string with _ inside then return true
 * - `${infer T}_${infer U}`: tells TS to get the element before and after the _ in the string
 * Then we use utility function like Lowercase/Capitalize to compute the new prop name
 * We use it recursively if there are many _ in the string
 * Doc: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-1.html#template-literal-types
 */
type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${Lowercase<T>}${Capitalize<SnakeToCamelCase<U>>}`
  : S

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S

/**
 * Explaination:
 * Remap the keys of the InputType
 * - InputType[K] extends Record<string, unknown>: means if InputType[K] is type of object/dictionnary, return true
 * - InputType[K] extends unknown[]: means if InputType[K] is type of array, return true
 * - We test against NonNullable<> to be handle optional types in that test
 * Then we use conditional types to return the correct matching type
 * Doc: https://www.typescriptlang.org/docs/handbook/advanced-types.html#conditional-types
 */
export type OutputToCamelCaseType<InputType> = {
  [K in keyof InputType as SnakeToCamelCase<K & string>]: NonNullable<
    InputType[K]
  > extends Record<string, unknown>
    ? OutputToCamelCaseType<InputType[K]>
    : InputType[K] extends unknown[]
      ? OutputToCamelCaseType<InputType[K][number]>[]
      : InputType[K]
}

export type OutputToSnakeCaseType<InputType> = {
  [K in keyof InputType as CamelToSnakeCase<K & string>]: NonNullable<
    InputType[K]
  > extends Record<string, unknown>
    ? OutputToSnakeCaseType<InputType[K]>
    : InputType[K] extends unknown[]
      ? OutputToSnakeCaseType<InputType[K][number]>[]
      : InputType[K]
}

export const tap = <T>(object: T, fn: (object: T) => void): any => {
  fn(object)
  return object
}

export const isObject = function (o: unknown): o is Record<string, unknown> {
  return o === Object(o) && !Array.isArray(o) && typeof o !== "function"
}

export const keysToCase = (o: unknown, caseFunction: (s: string) => string) => {
  if (isObject(o)) {
    const n = {}

    Object.keys(o).forEach((k) => {
      n[caseFunction(k)] = keysToCase(o[k], caseFunction)
    })

    return n
  } else if (Array.isArray(o)) {
    return o.map((i) => {
      return keysToCase(i, caseFunction)
    })
  }

  return o
}

export const keysToCamel = <InputType>(
  o: InputType
): InputType extends (infer T)[]
  ? OutputToCamelCaseType<T>[]
  : OutputToCamelCaseType<InputType> => keysToCase(o, toCamelCase)
export const keysToSnake = <InputType>(
  o: InputType
): InputType extends (infer T)[]
  ? OutputToSnakeCaseType<T>[]
  : OutputToSnakeCaseType<InputType> => keysToCase(o, toSnakeCase)
