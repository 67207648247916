import config from "core/config"

declare const window: Window &
  typeof globalThis & {
    isSnapchatInitialized: boolean
    snaptr: any
  }

const trackingEventsQueue: { event: string; params: unknown }[] = []

const trackEvent = ({ event, params }) => {
  window.snaptr("track", event, params)
}

const enqueueEvent = ({ event, params }) => {
  trackingEventsQueue.push({ event, params })
}

const trackEnqueuedEvents = () => {
  while (trackingEventsQueue.length) {
    trackEvent(trackingEventsQueue.pop()!)
  }
}

export async function initSnapchat() {
  if (!config?.isSnapchatEnabled || !config?.snapchatPixelId) return
  // already initialised
  if (window.isSnapchatInitialized) return

  // prettier-ignore
  /* eslint-disable*/
  {
    (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
    // @ts-expect-error: Snapchat provided obfuscated code
    {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
    // @ts-expect-error: Snapchat provided obfuscated code
    a.queue=[];var s='script';var r=t.createElement(s);r.async=!0;
    // @ts-expect-error: Snapchat provided obfuscated code
    r.src=n;var u=t.getElementsByTagName(s)[0];
    // @ts-expect-error: Snapchat provided obfuscated code
    u.parentNode.insertBefore(r,u);
    })(window,document, 'https://sc-static.net/scevent.min.js');
  }
  /* eslint-enable */
  window.snaptr("init", config.snapchatPixelId)
  window.snaptr("track", "PAGE_VIEW")
  window.isSnapchatInitialized = true
  trackEnqueuedEvents()
}

// Sometimes we use the trackConversion method when displaying the page.
// Because we have to wait for the user's consent, the track init methods are called on DOM Loaded.
// Therefore, the trackConversion method is called before the init method.
// In this case, we enqueue the event which will be dequeued to call the track method at the end of the init method.
export const trackConversionWithSnapchat = (event, params = {}) => {
  window.isSnapchatInitialized
    ? trackEvent({ event, params })
    : enqueueEvent({ event, params })
}
