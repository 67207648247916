import { EventProcessor } from "@sentry/types"

interface WindowWithJQuery extends Window {
  $: any
}

const global = window as WindowWithJQuery & typeof globalThis

const jqueryVersionEventProcessor: EventProcessor = (event) => {
  event.tags = {
    ...event.tags,
    jquery: (global.$ && global.$.fn && global.$.fn.jquery) || null,
  }

  return event
}

export default jqueryVersionEventProcessor
