import Url from "domurl"
import { isValidURIContent } from "utils/url"

const ALLOWED_KEYS = [
  "utm_name",
  "utm_term",
  "utm_source",
  "utm_medium",
  "utm_content",
  "utm_campaign",
  "mkwid", // Marin Software
]

export default function getUTMParams(query: string) {
  const url = new Url(query)
  const result: { [key: string]: string } = {}

  ALLOWED_KEYS.forEach((key) => {
    const value: string | null = url.query[key]
    if (value != null && value !== "") {
      result[key] = isValidURIContent(value) ? decodeURI(value) : value
    }
  })

  return result
}
