import { createError, notifyError } from "core/errors"
import tracking from "core/tracking"
import { query, queryAll, queryStrict } from "@drivy/dom-query"
import { displayHint } from "utils/hint"
import initAsyncSearchForm from "views/web/search/initAsyncSearchForm"
import { isInViewport } from "utils/dom"

interface OrderFormInterface {
  promoteSearchWithDates: boolean
  searchButtonSelector?: string
}

const MissingCountryError = createError("MissingCountryError")

export default (
  { promoteSearchWithDates, searchButtonSelector }: OrderFormInterface = {
    promoteSearchWithDates: true,
  }
) => {
  function resetHiddens() {
    queryStrict(".js_autocomplete_hidden_inputs").innerHTML = ""
  }

  function insertHiddens(fields) {
    const hiddenInputContainer = queryStrict(".js_autocomplete_hidden_inputs")

    resetHiddens()

    Object.keys(fields).forEach((name) => {
      const hiddenInput = document.createElement("input")
      hiddenInput.type = "hidden"
      hiddenInput.name = name
      hiddenInput.value = fields[name]

      hiddenInputContainer.appendChild(hiddenInput)
    })
  }

  const searchForm = queryStrict(".js_search_form")

  const datetimeWrapper = query(".js_datetime_range")

  initAsyncSearchForm({
    loadingError: createError("AsyncHomepageSearchFormError"),
    triggerer: searchForm,
    formToUnlock: searchForm,
    onChange: (data, datetimeRangeInput) => {
      const { isAccurate, showError } = data

      if (isAccurate) {
        insertHiddens({
          address: data.address,
          address_source: data.addressSource,
          poi_id: data.poiId,
          latitude: data.latitude,
          longitude: data.longitude,
          country_scope: data.country,
          postal_code: data.postalCode,
          city_display_name: data.cityDisplayName,
          administrative_area: data.administrativeArea,
        })

        if (datetimeWrapper) {
          datetimeWrapper.dataset.countryScope = data.country
        }

        if (promoteSearchWithDates) {
          if (datetimeRangeInput.hasMissingPart()) {
            if (document.activeElement !== document.body) {
              ;(document.activeElement as HTMLElement).blur()
            }

            datetimeRangeInput.openMissingPart()
          }
        } else {
          document.activeElement &&
            (document.activeElement as HTMLElement).blur()
          if (searchButtonSelector) {
            const searchButton = query(searchButtonSelector)
            if (searchButton) {
              !isInViewport(searchButton) &&
                window.scrollTo({
                  top:
                    searchButton.getBoundingClientRect().bottom -
                    window.innerHeight +
                    24,
                  behavior: "smooth",
                })
            }
          }
        }
      } else {
        resetHiddens()

        if (showError) {
          showAddressError()
        }
      }
    },
    afterInitialize: () => {
      const addressInput = queryStrict<HTMLInputElement>(
        ".cobalt-Autocomplete .cobalt-TextField__Input"
      )

      addressInput.addEventListener("focus", handleAddressFocus)
    },
  })

  function addressParts() {
    const hiddenInputs = queryAll<HTMLInputElement>(
      ".js_autocomplete_hidden_inputs input"
    )

    return hiddenInputs.reduce((acc: Record<string, string>, input) => {
      acc[input.name] = input.value
      return acc
    }, {})
  }

  function hasAddress() {
    const { address } = addressParts()
    return address && address.length > 0
  }

  function handleFormSubmit(e) {
    const fields = addressParts()

    if (!hasAddress()) {
      e.preventDefault()
      showAddressError()
    } else if (!fields.country_scope || !fields.country_scope.length) {
      e.preventDefault()
      notifyError(
        new MissingCountryError("No country set on home order form submit", {
          fields,
        })
      )
    }
  }

  function handleAddressFocus() {
    showDatetimeFields()
    tracking.event("homepage_address_form_focus")
  }

  function showDatetimeFields() {
    const scrollPosition = window.scrollY

    searchForm.classList.add("expand_date_fields")
    scrollPosition && window.scrollTo(0, scrollPosition)

    setTimeout(() => {
      searchForm.classList.add("expand_date_fields_animated")
    }, 1)
  }

  function showAddressError() {
    query("#order_address")?.focus()

    displayHint(".js_search_address_hint", 5000)
  }

  searchForm.on("submit", handleFormSubmit)
}
