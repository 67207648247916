import { onUsUserOnboardingSuccess } from "views/web/us_user_onboarding_success"
import {
  onWelcomeToGetaroundUS,
  TRACKING_EVENT_HOMEPAGE,
} from "views/web/welcome_to_getaround_us"

export const initUsOnboarding = () => {
  onWelcomeToGetaroundUS(TRACKING_EVENT_HOMEPAGE, async () => {
    const showWelcomeToGetaroundUSModal = (
      await import("../../search/modals/WelcomeNAUser")
    ).showWelcomeToGetaroundUSModal
    showWelcomeToGetaroundUSModal()
  })

  onUsUserOnboardingSuccess(async () => {
    const showUsUserOnboardingSuccessModal = (
      await import("./modals/UsUserOnboardingSuccess")
    ).showUsUserOnboardingSuccessModal
    showUsUserOnboardingSuccessModal()
  })
}
