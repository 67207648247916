import { query } from "@drivy/dom-query"
import { fadeInAnimate, fadeOutAnimate } from "./dom"

export function displayHint(hintSelector: string, displayDuration = 3000) {
  const transitionDuration = 1000 // ms
  const attributeDisplayInProgress = "inProgress"

  const hint = query(hintSelector)
  if (hint && !hint.getAttribute(attributeDisplayInProgress)) {
    hint.setAttribute(attributeDisplayInProgress, "true")

    fadeInAnimate(hint, transitionDuration, true)

    setTimeout(() => {
      fadeOutAnimate(hint, transitionDuration, true, () => {
        hint.removeAttribute(attributeDisplayInProgress)
      })
    }, displayDuration)
  }
}
