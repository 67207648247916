import { EventProcessor } from "@sentry/types"

const ignoreIfHandledEventProcessor: EventProcessor = (event) => {
  if (!event.tags) return event

  if (event.tags.handled) {
    // Ignore 401 errors that are handled by the app
    if (event.message?.includes("401")) return null
  }

  return event
}

export default ignoreIfHandledEventProcessor
