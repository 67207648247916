import { query, queryStrict } from "@drivy/dom-query"
import { createError, notifyError } from "core/errors"
import { keysToCamel } from "utils/object"
import { Locales } from "./models"

const initLocaleSwitch = () => {
  const placeholder = query(".js_locale_switch_placeholder")

  if (!placeholder) return

  const locales =
    placeholder.dataset &&
    placeholder.dataset.locales &&
    (keysToCamel(JSON.parse(placeholder.dataset.locales)) as Locales)

  if (!locales) return

  const AsyncLocalSwitchError = createError("AsyncLocalSwitchError")

  placeholder.addEventListener(
    "click",
    async () => {
      // loading state
      queryStrict(".js-locale-switch__select-icon").classList.add("c-hidden")
      queryStrict(".js-locale-switch__select-icon--loading").classList.remove(
        "c-hidden"
      )

      try {
        const mountLocaleSwitch = (
          await import("../../components/locale_switch/mount")
        ).default

        // the whole econtent of the placeholder is removed when mounting the React
        // content
        mountLocaleSwitch({
          placeholder,
          locales,
        })
      } catch (err) {
        notifyError(new AsyncLocalSwitchError(err.message))
      }
    },
    { once: true }
  )
}

export default initLocaleSwitch
