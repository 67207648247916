// Accessibility taken from https://inclusive-components.design/tabbed-interfaces/

import { scrollToLeft, ensureInHorizontalView } from "utils/dom"
import { compact } from "utils/array"
import { debounce } from "utils/function"

const SCROLLER_SELECTOR = ".js_tab_bar_scroller"
const SCROLL_AREA_SELECTOR = ".js_tab_bar_scroll_area"
const TAB_SELECTOR = ".js_tab_bar_scroll_area a"
const SCROLL_CONTROLS_SELECTOR = ".js_tab_bar_scroll_control"
const SCROLL_BUTTON_DISTANCE = 150

const panelForTab = (tab) => {
  const href = tab.getAttribute("href")
  if (href == null || href.length === 0 || href[0] !== "#") return
  return document.querySelector(href)
}

const initScrollListener = (tabBar: HTMLElement) => {
  const scrollArea = tabBar.querySelector<HTMLElement>(SCROLL_AREA_SELECTOR)!
  const scrollControls = Array.from(
    tabBar.querySelectorAll(SCROLL_CONTROLS_SELECTOR)
  )

  const scrollLeftButton = scrollControls
    .find((el) => el.matches("[data-direction='left']"))!
    .querySelector("button")!
  const scrollRightButton = scrollControls
    .find((el) => el.matches("[data-direction='right']"))!
    .querySelector("button")!

  const getPosition = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollArea
    const scrollRight = scrollWidth - clientWidth - scrollLeft

    return { left: scrollLeft, right: scrollRight }
  }

  const update = () => {
    const { left, right } = getPosition()

    scrollLeftButton.toggleAttribute("hidden", left <= 0)
    scrollRightButton.toggleAttribute("hidden", right <= 0)

    scrollControls.forEach((el) =>
      el.toggleAttribute("hidden", left <= 0 && right <= 0)
    )
  }

  scrollArea.addEventListener("scroll", update, false)
  window.addEventListener("resize", debounce(update, 500), false)

  scrollLeftButton.addEventListener("click", () => {
    const { left } = getPosition()
    scrollToLeft(scrollArea, left - SCROLL_BUTTON_DISTANCE, 150)
  })

  scrollRightButton.addEventListener("click", () => {
    const { left } = getPosition()
    scrollToLeft(scrollArea, left + SCROLL_BUTTON_DISTANCE, 150)
  })

  update()
}

export default (tabBar: HTMLElement, { onActivate = (_tab) => {} } = {}) => {
  const tabs = Array.from(tabBar.querySelectorAll<HTMLElement>(TAB_SELECTOR))
  const panels = compact(tabs.map(panelForTab))

  // Check if we are indeed in a real tab system or if we're linking to external tabs
  const isInPageTabBar = tabs.length === panels.length
  if (!isInPageTabBar && panels.length)
    throw new Error("Can't mix in-page targets and external links in a TabBar ")

  const tabFromLocation = () =>
    isInPageTabBar &&
    window.location.hash &&
    window.location.hash.length &&
    tabBar.querySelector<HTMLElement>(`[href="${window.location.hash}"]`)

  // Use the first tab height to fix the tab bar height and hide horizontal scroll bar
  const setTabBarHeight = () => {
    const scroller = tabBar.querySelector<HTMLElement>(SCROLLER_SELECTOR)!
    const scrollArea = tabBar.querySelector<HTMLElement>(SCROLL_AREA_SELECTOR)!

    const height = tabBar.querySelectorAll(TAB_SELECTOR)[0].clientHeight

    scroller.style.height = `${height}px`
    // 30px because no scroll bar will be more than that
    scrollArea.style.paddingBottom = "30px"
  }

  const switchTab = (oldTab, newTab) => {
    newTab.focus()
    newTab.removeAttribute("tabindex")
    newTab.setAttribute("aria-selected", true)
    oldTab.removeAttribute("aria-selected")
    oldTab.setAttribute("tabindex", "-1")

    ensureInHorizontalView(
      tabBar.querySelector(SCROLL_AREA_SELECTOR)!,
      newTab,
      150
    )

    if (isInPageTabBar) {
      const panelForNewTab = panelForTab(newTab)
      if (panelForNewTab instanceof HTMLElement) panelForNewTab.hidden = false

      const panelForOldTab = panelForTab(oldTab)
      if (panelForOldTab instanceof HTMLElement) panelForOldTab.hidden = true

      history.replaceState({}, "", newTab.href)

      onActivate(newTab)
    }
  }

  const goToTab = (tab) => {
    const currentTab = tabBar.querySelector("[aria-selected]")
    if (tab !== currentTab) switchTab(currentTab, tab)
  }

  tabs.forEach((tab) => {
    tab.addEventListener("click", (e) => {
      if (isInPageTabBar) e.preventDefault()
      goToTab(e.currentTarget)
    })

    if (isInPageTabBar) {
      // Accessibility:
      //

      tab.setAttribute("role", "tab")
      tab.setAttribute("tabindex", "-1")

      // tabs can be navigated with arrow keys instead of tab key
      tab.addEventListener("keydown", (e) => {
        const index = tabs.indexOf(e.currentTarget as HTMLElement)
        const dir =
          e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : null

        if (dir == null) return

        e.preventDefault()
        if (tabs[dir]) switchTab(e.currentTarget, tabs[dir])
      })
    }
  })

  if (isInPageTabBar) {
    window.addEventListener(
      "hashchange",
      () => {
        const tab = tabFromLocation()
        if (tab) goToTab(tab)
      },
      false
    )

    tabs.map(panelForTab).forEach((panel) => {
      panel.setAttribute("role", "tabpanel")
      panel.setAttribute("tabindex", "-1")
      panel.hidden = true
    })
  }

  initScrollListener(tabBar)

  // Activate initial tab
  // haml is not doing well with boolean attributes so we have to exclude
  // string values set at `false`
  const initialTab =
    tabFromLocation() ||
    tabBar.querySelector<HTMLElement>(
      '[aria-selected]:not([aria-selected="false"])'
    ) ||
    tabs[0]

  initialTab.setAttribute("aria-selected", "true")

  // Clean up other tabs that might have a wrong attribute value
  tabs
    .filter((tab) => tab !== initialTab)
    .forEach((tab) => tab.removeAttribute("aria-selected"))

  ensureInHorizontalView(
    tabBar.querySelector(SCROLL_AREA_SELECTOR)!,
    initialTab
  )

  if (isInPageTabBar) panelForTab(initialTab).hidden = false

  setTabBarHeight()
}
