// https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigation

import { delve } from "utils/object"

export default function navigationType() {
  return (
    {
      0: "navigate",
      1: "reload",
      2: "back_forward",
      255: "other",
    }[delve(window, "performance.navigation.type") as number] || null
  )
}
