export const mark = (markName: string) => {
  if (window.performance == null || window.performance.mark == null) {
    return
  }

  window.performance.mark(markName)
}

export const measure = (
  measureName: string,
  startMark: string,
  endMark: string
) => {
  if (window.performance == null || window.performance.measure == null) {
    return
  }

  window.performance.measure(measureName, startMark, endMark)
}
