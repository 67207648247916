import { queryAll } from "@drivy/dom-query"
import tracking from "core/tracking"
import { throttle } from "utils/function"
import { trackConversionWithCriteo } from "drivy_bootstrap/utils/tracking"

const TRACK_HOW_IT_WORKS_VIEWED_THROTTLE_RATE = 50

export const initHomepageTracking = () => {
  tracking.page("homepage")
  trackConversionWithCriteo("viewHome")

  queryAll(".js_mobile-app-section__app_badge").on("click", function (e) {
    const trigger = e.currentTarget as HTMLElement
    const { trackingEventName } = trigger.dataset

    tracking.event(trackingEventName as string)
  })

  const howItWorksSection = document.querySelector(".js_how_it_works_section")
  const windowHeight = () => {
    return window.innerHeight || document.documentElement.clientHeight
  }
  const trackHowItWorksSectionViewed = throttle(() => {
    if (
      howItWorksSection &&
      windowHeight() &&
      howItWorksSection.getBoundingClientRect().bottom < windowHeight()
    ) {
      tracking.event("homepage_hiw_viewed")

      document.removeEventListener("scroll", trackHowItWorksSectionViewed)
    }
  }, TRACK_HOW_IT_WORKS_VIEWED_THROTTLE_RATE)

  document.addEventListener("scroll", trackHowItWorksSectionViewed, {
    passive: true,
  })
  trackHowItWorksSectionViewed()
}
