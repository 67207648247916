import config from "core/config"
import loadRemoteScript from "../../loadRemoteScript"
import { get as getAnonymousId } from "../capturTracking/anonymous_id"

// Global variables set by Google Ads script
declare const window: Window &
  typeof globalThis & {
    gtag: any
    dataLayer: any
    isGoogleAdsSetBeforeConsent: boolean
    isGoogleAdsSetAfterConsent: boolean
  }

let loadingPromise: Promise<void>

export const initGoogleAdsBeforeConsent = async () => {
  const conversionId = config?.gtag?.conversionId
  const analyticsTagId = config?.gtag?.analyticsTagId

  if (!conversionId || !analyticsTagId) return
  // already initialised
  if (window.isGoogleAdsSetBeforeConsent) return
  if (loadingPromise) return loadingPromise

  const init = async () => {
    window.dataLayer = window.dataLayer || []
    window.gtag = function () {
      // Can't use arrow func + destructuring as Google expects
      // arguments objects in dataLayer (not an array of arguments).
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments)
    }

    window.gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
    })

    window.gtag("js", new Date())

    window.gtag("set", "linker", { domains: config.gtag?.domains })

    window.gtag("config", conversionId, {
      conversion_linker: false,
      allow_enhanced_conversions: true,
    })

    window.gtag("config", analyticsTagId, {
      user_id: getAnonymousId(),
    })

    await loadRemoteScript(
      `https://www.googletagmanager.com/gtag/js?id=${conversionId}`
    )

    window.isGoogleAdsSetBeforeConsent = true
    window.isGoogleAdsSetAfterConsent = false
  }

  loadingPromise = init()
  return loadingPromise
}

export const setGoogleAdsConsent = async () => {
  window.gtag("consent", "update", {
    ad_storage: "granted",
    analytics_storage: "granted",
    ad_user_data: "granted",
    ad_personalization: "granted",
  })

  window.isGoogleAdsSetAfterConsent = true
}

const getConversionId = () => {
  const conversionId = config?.gtag?.conversionId

  if (!conversionId) throw new Error("Could not find Google Ads account")

  return conversionId
}

export const trackConversion = (
  label: unknown,
  value?: unknown,
  currency?: unknown,
  transaction_id?: unknown
) => {
  if (!("gtag" in window)) return

  const params: {
    send_to: string
    value?: unknown
    currency?: unknown
    transaction_id?: unknown
  } = {
    send_to: `${getConversionId()}/${label}`,
  }

  if (value && currency) {
    params.value = value
    params.currency = currency
  }

  if (transaction_id) {
    params.transaction_id = transaction_id
  }

  if (config?.currentUserEmail) {
    window.gtag("set", "user_data", { email: config.currentUserEmail })
  }
  window.gtag("event", "conversion", params)
}

export const trackRemarketing = (params = {}) => {
  if (!("gtag" in window)) return

  window.gtag("event", "page_view", {
    send_to: getConversionId(),
    country: config.country,
    ...params,
  })
}
