import Cookies from "js-cookie"
import config from "core/config"

export type CookieCategoryType =
  | "functional" // functional tracking doesn't need consent, always true
  | "audience"
  | "performance"
  | "targeting"
export type CookieConsentType = {
  [K in CookieCategoryType]?: boolean
}

export const COOKIE_CONSENT_WRAPPER_CLASS = ".js_cookie-consent"
const COOKIE_CONSENT_NAME = "cookie_consent"

export const getCookieConsent = (): CookieConsentType | null => {
  try {
    const content = JSON.parse(Cookies.get(COOKIE_CONSENT_NAME) || "")
    return content.updated_at ? content.categories : null
  } catch (e) {
    return null
  }
}

export const isCookieConsentGivenFor = (category: CookieCategoryType) => {
  if (!config.trackingEnabled) return false
  if (category === "functional") return true

  return !!getCookieConsent()?.[category]
}
