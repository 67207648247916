import { getSimpleUserInfo } from "utils/simple_user_info"
import tracking from "core/tracking"

const UBER_DRIVER_REFERRAL_CONTEXT = "uber_driver"
const UBER_ACCOUNT_LINKING_SUCCESS = "#uber-account-linking-success"
const UBER_ACCOUNT_LINKING_ALERT = "#uber-account-linking-alert"

export const TRACKING_EVENT_LANDING_PAGE_VIEWED =
  "uber_profiles_landing_page_viewed"
export const TRACKING_EVENT_LANDING_PAGE_CLICKED =
  "uber_profiles_landing_page_clicked"
export const TRACKING_EVENT_ACCOUNT_LINKING_SUCCESS =
  "uber_profiles_account_linking_success"
export const TRACKING_EVENT_ACCOUNT_LINKING_ERROR =
  "uber_profiles_account_linking_error"

function isStep(hash: string) {
  return window.location.hash === hash
}

function isUberDriverReferralContext() {
  const referralContext =
    new URLSearchParams(window.location.search).get("referral_context") || ""
  return referralContext === UBER_DRIVER_REFERRAL_CONTEXT
}

export async function handleUberAccountLinkingModals() {
  if (isUberDriverReferralContext()) {
    const showUberLinkingStartModal = (
      await import("./pages/homepage/modals/UberLinkingStartModal")
    ).showUberLinkingStartModal
    const user = await getSimpleUserInfo()
    showUberLinkingStartModal(user)
    tracking.event(TRACKING_EVENT_LANDING_PAGE_VIEWED)
  }
  if (isStep(UBER_ACCOUNT_LINKING_SUCCESS)) {
    const showUberLinkingSuccessModal = (
      await import("./pages/homepage/modals/UberLinkingEndModal")
    ).showUberLinkingSuccessModal
    showUberLinkingSuccessModal()
    tracking.event(TRACKING_EVENT_ACCOUNT_LINKING_SUCCESS)
  }
  if (isStep(UBER_ACCOUNT_LINKING_ALERT)) {
    const showUberLinkingAlertModal = (
      await import("./pages/homepage/modals/UberLinkingEndModal")
    ).showUberLinkingAlertModal
    showUberLinkingAlertModal()
    tracking.event(TRACKING_EVENT_ACCOUNT_LINKING_ERROR)
  }
}
