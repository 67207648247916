const US_USER_ONBOARDING_SUCCESS_HASH = "#us_onboarding_success"

function isUsUserOnboardingSuccess() {
  return window.location.hash === US_USER_ONBOARDING_SUCCESS_HASH
}

export async function onUsUserOnboardingSuccess(callback: () => void) {
  if (isUsUserOnboardingSuccess()) {
    window.history.replaceState(
      null,
      document.title,
      window.location.pathname + window.location.search
    )

    callback()
  }
}
