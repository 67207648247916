import config from "core/config"
import getDefaultProperties from "./default_properties"
import sendPayload from "./send_payload"
import { Properties } from "."

export default function event(name: string, properties: Properties = {}) {
  const args = Object.assign(
    { name: name || null },
    properties,
    getDefaultProperties()
  )

  sendPayload(config.routes.trackingEventPath, args)
}
