import config from "core/config"

export function createError(name: string) {
  return class extends Error {
    fingerprint: string[]
    readonly message: string

    constructor(message: string, meta = {}) {
      super(message)

      // add meta props to the error object
      // handled then by ExtraErrorData integration
      for (const prop in meta) {
        if (Object.hasOwnProperty.call(meta, prop)) {
          this[prop] = meta[prop]
        }
      }

      this.name = name
      this.message = message
      this.fingerprint = [name]
    }
  }
}

export function notifyError(error: Error) {
  try {
    throw error
  } catch (e) {
    if (config.env === "development") {
      // eslint-disable-next-line no-console
      console.error("Notify error", e.name, e.message, e.meta)
    }

    if ("Sentry" in window) (window as any).Sentry.captureException(e)
  }
}

export function notifyException(error: Error, group: string) {
  const context = group
    ? { fingerprint: ["{{ default }}", group], tags: { error_group: group } }
    : {}

  if (config.env === "development") {
    console.error("notifyException", error, context)
    console.dir(error)
  }

  if ("Sentry" in window) {
    ;(window as any).Sentry.captureException(error, context)
  }
}

export function leaveBreadcrumb(message: string, meta = {}) {
  if (config.env === "development") {
    // eslint-disable-next-line no-console
    console.info("Leaving breadcrumb:", message, meta)
  }

  if ("Sentry" in window)
    (window as any).Sentry.addBreadcrumb({ message, data: meta })
}
