// Ensure we only create a single promise for a given path to avoid

import { createError } from "core/errors"

const LoadingError = createError("LoadRemoteScriptLoadingError")

type loadPropsType = {
  path: string
  onSuccess: () => void
  onError: (error: Error) => void
}

const load = ({ path, onSuccess, onError }: loadPropsType) => {
  const script = document.createElement("script")
  script.src = path
  script.type = "text/javascript"
  script.async = true

  script.onload = onSuccess
  script.onerror = () => onError(new LoadingError(path))
  document.head.appendChild(script)
}

export default function loadRemoteScript(path: string) {
  return new Promise<void>((resolve, reject) => {
    load({ path, onSuccess: resolve, onError: reject })
  })
}
