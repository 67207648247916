import { queryAll } from "@drivy/dom-query"

const ROOT_MARGIN = "200px" // use to trigger upstream lazyloading before visible

const xsListeners: (() => void)[] = []
const smListeners: (() => void)[] = []
const mdListeners: (() => void)[] = []
const lgListeners: (() => void)[] = []

// getaround breakpoints. Should match cobalt ones

const SM_BREAKPOINT_WIDTH = 480
const MD_BREAKPOINT_WIDTH = 992
const LG_BREAKPOINT_WIDTH = 1200

const xsMediaQuery = window.matchMedia(
  `(max-width: ${SM_BREAKPOINT_WIDTH - 1}px)`
)
const smMediaQuery = window.matchMedia(
  `(min-width: ${SM_BREAKPOINT_WIDTH}px) and (max-width: ${
    MD_BREAKPOINT_WIDTH - 1
  }px)`
)
const mdMediaQuery = window.matchMedia(
  `(min-width: ${MD_BREAKPOINT_WIDTH}px) and (max-width: ${
    LG_BREAKPOINT_WIDTH - 1
  }px)`
)
const lgMediaQuery = window.matchMedia(`(min-width: ${LG_BREAKPOINT_WIDTH}px)`)

function handleXsChange(e) {
  if (e.matches) {
    xsListeners.forEach((listener) => listener())
  }
}

function handleSmChange(e) {
  if (e.matches) {
    smListeners.forEach((listener) => listener())
  }
}

function handleMdChange(e) {
  if (e.matches) {
    mdListeners.forEach((listener) => listener())
  }
}

function handleLgChange(e) {
  if (e.matches) {
    lgListeners.forEach((listener) => listener())
  }
}

// Safari < 14 only supports addListener
xsMediaQuery.addEventListener
  ? xsMediaQuery.addEventListener("change", handleXsChange)
  : xsMediaQuery.addListener(handleXsChange)

smMediaQuery.addEventListener
  ? smMediaQuery.addEventListener("change", handleSmChange)
  : smMediaQuery.addListener(handleSmChange)

mdMediaQuery.addEventListener
  ? mdMediaQuery.addEventListener("change", handleMdChange)
  : mdMediaQuery.addListener(handleMdChange)

lgMediaQuery.addEventListener
  ? lgMediaQuery.addEventListener("change", handleLgChange)
  : lgMediaQuery.addListener(handleLgChange)

export const replaceBackgroundImage = (el: HTMLElement) => {
  if (!el.dataset.backgroundImage) return

  const setBackgroundImage = (url: string) => {
    if (el.dataset.backgroundImage!.includes("dpr=")) {
      if (el.dataset.backgroundImage!.includes("dpr=2")) {
        // we add dpr=1 for device not supporting dpr=2
        el.style.backgroundImage = `image-set("${url.replace(
          "dpr=2",
          "dpr=1"
        )}" 1x, "${url}" 2x)`
      } else {
        // we could add dpr=2 support but for now let's considere that having dpr=1 set means that we don't want dpr=2 (for aggressive optimization)
        el.style.backgroundImage = `url('${url}')`
      }
    } else {
      const separator = url.includes("?") ? "&" : "?"
      el.style.backgroundImage = `image-set("${url}${separator}dpr=1" 1x, "${url}${separator}dpr=2" 2x)`
    }
  }

  const setLgBackgroundImage = () => {
    if (el.dataset.lgBgImg) {
      setBackgroundImage(el.dataset.lgBgImg)
    } else if (el.dataset.mdBgImg) {
      setBackgroundImage(el.dataset.mdBgImg)
    } else if (el.dataset.smBgImg) {
      setBackgroundImage(el.dataset.smBgImg)
    } else if (el.dataset.xsBgImg) {
      setBackgroundImage(el.dataset.xsBgImg)
    }
  }

  const setMdBackgroundImage = () => {
    if (el.dataset.mdBgImg) {
      setBackgroundImage(el.dataset.mdBgImg)
    } else if (el.dataset.smBgImg) {
      setBackgroundImage(el.dataset.smBgImg)
    } else if (el.dataset.xsBgImg) {
      setBackgroundImage(el.dataset.xsBgImg)
    }
  }

  const setSmBackgroundImage = () => {
    if (el.dataset.smBgImg) {
      setBackgroundImage(el.dataset.smBgImg)
    } else if (el.dataset.xsBgImg) {
      setBackgroundImage(el.dataset.xsBgImg)
    }
  }

  const setXsBackgroundImage = () => {
    if (el.dataset.xsBgImg) {
      setBackgroundImage(el.dataset.xsBgImg)
    }
  }

  const setResponsiveBackgroundImage = () => {
    if (lgMediaQuery.matches) {
      setLgBackgroundImage()
    } else if (mdMediaQuery.matches) {
      setMdBackgroundImage()
    } else if (smMediaQuery.matches) {
      setSmBackgroundImage()
    } else if (xsMediaQuery.matches) {
      setXsBackgroundImage()
    } else {
      // matchMedia matches not triggered
      const viewportWidth = window.innerWidth
      if (viewportWidth >= LG_BREAKPOINT_WIDTH) {
        setLgBackgroundImage()
      } else if (
        viewportWidth >= MD_BREAKPOINT_WIDTH &&
        viewportWidth < LG_BREAKPOINT_WIDTH
      ) {
        setMdBackgroundImage()
      } else if (
        viewportWidth >= SM_BREAKPOINT_WIDTH &&
        viewportWidth < MD_BREAKPOINT_WIDTH
      ) {
        setSmBackgroundImage()
      } else if (viewportWidth < SM_BREAKPOINT_WIDTH) {
        setXsBackgroundImage()
      }
    }
  }

  const images = el.dataset.backgroundImage.split("|")

  if (images.length > 1) {
    images.forEach((image) => {
      if (image.startsWith("sm:")) {
        // from sm
        smListeners.push(setResponsiveBackgroundImage)
        mdListeners.push(setResponsiveBackgroundImage)
        lgListeners.push(setResponsiveBackgroundImage)
        el.dataset.smBgImg = image.substring(3)
      } else if (image.startsWith("md:")) {
        //from md
        mdListeners.push(setResponsiveBackgroundImage)
        lgListeners.push(setResponsiveBackgroundImage)
        el.dataset.mdBgImg = image.substring(3)
      } else if (image.startsWith("lg:")) {
        //from lg
        lgListeners.push(setResponsiveBackgroundImage)
        el.dataset.lgBgImg = image.substring(3)
      } else {
        // default breakpoint (mobile first)
        xsListeners.push(setResponsiveBackgroundImage)
        el.dataset.xsBgImg = image
      }
    })

    setResponsiveBackgroundImage() // init
  } else {
    setBackgroundImage(el.dataset.backgroundImage)
  }

  el.classList.remove("lazy")
}

function replaceSrc(img: HTMLImageElement) {
  if (img.dataset.src) {
    img.src = img.dataset.src
  }
  if (img.dataset.srcset) {
    img.srcset = img.dataset.srcset
  }
  img.classList.remove("lazy")
}

function replaceStyle(el: HTMLElement) {
  if (el.dataset.style) {
    el.setAttribute("style", el.dataset.style)
  }
  el.classList.remove("lazy")
}

export function registerLazyImages() {
  const lazyElements = queryAll(".lazy")

  if (!lazyElements) return

  if (typeof window !== "undefined" && window.IntersectionObserver) {
    const lazyCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const isImgTag =
            (entry.target as HTMLElement).tagName.toLowerCase() === "img"
          if (isImgTag) {
            replaceSrc(entry.target as HTMLImageElement)
          } else if ((entry.target as HTMLElement).dataset.style) {
            replaceStyle(entry.target)
          } else {
            replaceBackgroundImage(entry.target)
          }

          observer.unobserve(entry.target)
        }
      })
    }

    const lazyObserver = new IntersectionObserver(lazyCallback, {
      rootMargin: ROOT_MARGIN,
    })

    lazyElements.forEach((el) => {
      lazyObserver.observe(el)
    })
  } else {
    lazyElements.forEach((el) => {
      replaceBackgroundImage(el)
    })
  }
}
