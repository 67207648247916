import { EnhancedHTMLElement, toEnhancedHTMLElement } from "@drivy/dom-query"

export const showInputLoadingState = <T extends HTMLElement>(
  element: T & EnhancedHTMLElement
) => {
  if (element.parentElement) {
    const parent = toEnhancedHTMLElement(element.parentElement)
    parent.query(".js_input_loading_state")?.classList.remove("c-hidden")
  }
}

export const hideInputLoadingState = <T extends HTMLElement>(
  element: T & EnhancedHTMLElement
) => {
  if (element.parentElement) {
    const parent = toEnhancedHTMLElement(element.parentElement)
    parent.query(".js_input_loading_state")?.classList.add("c-hidden")
  }
}
