import { fetchGetOptions } from "api/fetch"
import { UserMenuAPIRawType, UserType } from "components/user_menu/models"

export async function getSimpleUserInfo(): Promise<UserType | null> {
  const response = await fetch(
    `/api/internal/user_menu?current_path=${window.location.pathname}`,
    {
      ...fetchGetOptions,
      credentials: "include",
    }
  )
  if (response.ok) {
    try {
      const userMenu = (await response.json()) as UserMenuAPIRawType
      const userInfo = userMenu.user_infos as UserType
      return userInfo
    } catch (err) {
      return null
    }
  }
  return null
}
