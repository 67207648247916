import config from "core/config"
import type Adjust from "@adjustcom/adjust-web-sdk"

declare const window: Window &
  typeof globalThis & {
    isAdjustInitialized: boolean
  }

type TrackingEvent = {
  eventToken: string
  params?: {
    deduplicationId?: string
    revenue?: number
    currency?: string
  }
}

let AdjustClient: typeof Adjust | null = null

const trackingEventsQueue: TrackingEvent[] = []

const trackEvent = async ({ eventToken, params = {} }: TrackingEvent) => {
  if (!window.isAdjustInitialized) return

  AdjustClient?.trackEvent({
    eventToken,
    ...params,
  })
}

const enqueueEvent = ({ eventToken, params }) => {
  trackingEventsQueue.push({ eventToken, params })
}

const trackEnqueuedEvents = () => {
  while (trackingEventsQueue.length) {
    const event = trackingEventsQueue.pop()
    if (event) {
      trackEvent(event)
    }
  }
}

export const initAdjust = async () => {
  if (window.isAdjustInitialized) return

  AdjustClient = (await import(
    "@adjustcom/adjust-web-sdk"
  )) as unknown as typeof Adjust
  AdjustClient?.initSdk({
    appToken: config.adjust.appToken,
    environment: config.env !== "production" ? "sandbox" : "production",
    // logLevel: 'verbose', // Uncomment to enable verbose logging
  })
  window.isAdjustInitialized = true
  trackEnqueuedEvents()
}

// Sometimes we use the trackConversion method when displaying the page.
// Because we have to wait for the user's consent, the track init methods are called on DOM Loaded.
// Therefore, the trackConversion method is called before the init method.
// In this case, we enqueue the event which will be dequeued to call the track method at the end of the init method.
export const trackConversionWithAdjust = (eventToken, params = {}) => {
  window.isAdjustInitialized
    ? trackEvent({ eventToken, params })
    : enqueueEvent({ eventToken, params })
}
