import config from "core/config"

// Global variables set by Hotjar script
declare const window: Window &
  typeof globalThis & {
    _hjSettings: any
    useHotjar: boolean
    isHotJarSet: boolean
  }

export async function initHotjar() {
  // Hotjar is not loaded on all pages, so we filter using a global useHotjar variable
  if (!config?.hotjarId || !window.useHotjar) return
  // already initialised
  if (window.isHotJarSet) return

  // prettier-ignore
  /* eslint-disable*/
  {
    // @ts-expect-error: Hotjar provided obfuscated code
    ;(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    // @ts-expect-error: Hotjar provided obfuscated code
    h._hjSettings={hjid:config.hotjarId,hjsv:6}; a=o.getElementsByTagName('head')[0];
    // @ts-expect-error: Hotjar provided obfuscated code
    r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    // @ts-expect-error: Hotjar provided obfuscated code
    a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }
  /* eslint-enable */
  window.isHotJarSet = true
}
