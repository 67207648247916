import { queryAll } from "@drivy/dom-query"
import { fadeOutAnimate } from "utils/dom"

export default () => {
  const onCalloutClose = (e: Event) => {
    e.preventDefault()

    if (!e.target) return
    const callout: HTMLElement | null = (e.target as Element).closest(
      ".callout, .js_callout"
    )
    if (!callout) return

    fadeOutAnimate(callout, 300, false, () => {
      callout.dispatchEvent(new Event("callout:close"))
      callout.remove()
    })
  }
  queryAll(".js_callout_close").forEach((el) => {
    el.addEventListener("click", onCalloutClose)
  })
}
