import param from "core/param"
import { HEADER_KEY, TOKEN } from "utils/csrf"

interface TrackingData {
  [k: string]: string | number | boolean | null | undefined
}

type SendFunction = (url: string, data: TrackingData) => boolean

const dataWithSource = (data: TrackingData, transport: string) => ({
  tracking: Object.assign({}, data, {
    tracking_source: `frontend/${transport}`,
  }),
})

const sendXhr: SendFunction = (url, data) => {
  const finalData = dataWithSource(data, "xhr")

  fetch(url + "?" + param(finalData), {
    method: "HEAD",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      [HEADER_KEY]: TOKEN || "",
    },
  })

  return true
}

const sendBeacon: SendFunction = (url, data) => {
  if (!("sendBeacon" in navigator)) return false

  // eslint-disable-next-line jquery/no-param
  const serializedData = param(dataWithSource(data, "beacon"))

  // Chrome does not support application/json for sendBeacon anymore
  // cf http://crbug.com/490015
  // using form data instead for better support
  const blob = new Blob([serializedData], {
    type: "application/x-www-form-urlencoded; charset=UTF-8",
  })

  try {
    // Beacon returns false if the browser couldn't queue the data for transfer
    // (e.g: the data was too big)
    // https://sentry.io/drivy/js-production/issues/305682678/
    return navigator.sendBeacon(url, blob)
  } catch (e) {
    return false
  }
}

const sendPayload: SendFunction = (url, data) => {
  return sendBeacon(url, data) || sendXhr(url, data)
}

export default sendPayload
