import loadRemoteScript from "../../loadRemoteScript"
import config from "core/config"

// Global variables set by Criteo script
declare const window: Window &
  typeof globalThis & {
    criteo_q: any
    isCriteoInitialized: boolean
  }

let loadingPromise: Promise<void>

const trackingEventsQueue: { event: string; params: unknown }[] = []

const enqueueEvent = ({ event, params }) => {
  trackingEventsQueue.push({ event, params })
}

const trackEvent = ({ event, params }) => {
  if (!config?.currentUserEmailSHA256) return

  window.criteo_q = window.criteo_q || []
  const deviceType = /iPad/.test(navigator.userAgent)
    ? "t"
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
          navigator.userAgent
        )
      ? "m"
      : "d"
  window.criteo_q.push(
    { event: "setAccount", account: config.criteoPartnerId },
    {
      event: "setEmail",
      email: config.currentUserEmailSHA256,
      hash_method: "sha256",
    },
    { event: "setSiteType", type: deviceType },
    { event, ...params }
  )
}

const trackEnqueuedEvents = () => {
  while (trackingEventsQueue.length) {
    trackEvent(trackingEventsQueue.pop()!)
  }
}

export const initCriteo = async () => {
  if (!config.isCriteoEnabled) return
  if (window.isCriteoInitialized) return
  if (loadingPromise) return loadingPromise

  const loadCriteo = async () => {
    await loadRemoteScript(
      `https://dynamic.criteo.com/js/ld/ld.js?a=${config.criteoPartnerId}`
    )
    window.isCriteoInitialized = true
    trackEnqueuedEvents()
  }

  loadingPromise = loadCriteo()
  return loadingPromise
}

// Sometimes we use the trackConversion method when displaying the page.
// Because we have to wait for the user's consent, the track init methods are called on DOM Loaded.
// Therefore, the trackConversion method is called before the init method.
// In this case, we enqueue the event which will be dequeued to call the track method at the end of the init method.
export const trackConversionWithCriteo = async (event, params = {}) => {
  window.isCriteoInitialized
    ? trackEvent({ event, params })
    : enqueueEvent({ event, params })
}
