import config from "core/config"

declare const window: Window &
  typeof globalThis & {
    isTiktokInitialized: boolean
    ttq: any
  }

const trackingEventsQueue: { event: string; params: unknown }[] = []

const trackEvent = ({ event, params }) => {
  window.ttq.track(event, params)
}

const enqueueEvent = ({ event, params }) => {
  trackingEventsQueue.push({ event, params })
}

const trackEnqueuedEvents = () => {
  while (trackingEventsQueue.length) {
    trackEvent(trackingEventsQueue.pop()!)
  }
}

export async function initTiktok() {
  if (!config?.isTiktokEnabled || !config?.tiktokPixelId) return
  // already initialised
  if (window.isTiktokInitialized) return

  // prettier-ignore
  /* eslint-disable*/
  {
    // @ts-expect-error: Tiktok provided obfuscated code
    !function (w, d, t) {
      // @ts-expect-error: Tiktok provided obfuscated code
      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
      ttq.load(config.tiktokPixelId);
      ttq.page();
    }(window, document, 'ttq');
  }
  /* eslint-enable */
  window.isTiktokInitialized = true
  trackEnqueuedEvents()
}

// Sometimes we use the trackConversion method when displaying the page.
// Because we have to wait for the user's consent, the track init methods are called on DOM Loaded.
// Therefore, the trackConversion method is called before the init method.
// In this case, we enqueue the event which will be dequeued to call the track method at the end of the init method.
export const trackConversionWithTiktok = (event, params = {}) => {
  window.isTiktokInitialized
    ? trackEvent({ event, params })
    : enqueueEvent({ event, params })
}
