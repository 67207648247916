import { LocaleConfig } from "utils/date/create_locale"
import { FlashStatus } from "components/flash/flash"

// this interface is partial, it should be improved as we type files
interface Config {
  flashMessage: {
    message: string
    status: FlashStatus
  }
  locale: string
  cookieDomain: string
  currencyFormatting: {
    separator: string
    delimiter: string
    format: string
  }
  routes: {
    [key: string]: string
  }
  sentryDsn?: string
  release: string | null
  env: string
  now: string | null
  action: string
  country: string
  jsErrorsWhitelistedUrls: string[] | null
  dateLocaleOptions: LocaleConfig
  i18nKeys?: {
    [key: string]: any
  }
  experiment?: {
    slot: { ua: string }
    value: string
    name: string
  }
  trackingEnabled: boolean
  tracking?: any
  facebookPixelId?: string
  hotjarId?: string
  tiktokPixelId?: string
  snapchatPixelId?: string
  criteoPartnerId?: string
  bingId?: string
  ga: {
    config: {
      domain: string
      account: string
    } | null
  }
  gtag?: {
    domains: string
    conversionId?: string
    analyticsTagId?: string
    conversionEvents?: { [key: string]: string }
  }
  firebaseAppConfiguration: {
    apiKey: string
    authDomain: string
    projectId: string
    appId: string
  }
  brazeWebSdk: {
    baseUrl: string | null
    apiKey: string | null
  }
  isAdmoTVEnabled: boolean
  isTiktokEnabled: boolean
  isSnapchatEnabled: boolean
  isCriteoEnabled: boolean
  mapsApiEndpoint?: string
  mapsApiKey?: string
  assetPaths: string[]
  stripe: {
    publishableKey: string
    scriptSrc: string
    sca: boolean
  }
  carGroupsAccess: boolean
  carPhotoUploaderLargeDimensions: {
    w: number
    h: number
  }
  cobaltInlineModal: {
    classes: {
      TITLE: string
      BODY: string
      FOOTER: string
      TRIGGER_CLOSE: string
      TRIGGER_RELOAD: string
      TRIGGER_NEXT: string
      TRIGGER_PREVIOUS: string
      TRIGGER_FORM_CONFIRM: string
    }
    attributes: {
      MODAL_ID: string
      MODAL_ARIA_LABEL: string
      MODAL_SRC: string
      FORM_CONFIRM_MODAL_ID: string
      CLOSEABLE: string
      BODY_SPACING: string
      LIGHT_HEADER: string
    }
  }
  currentUserEmail?: string
  currentUserEmailSHA256?: string
  host: string
  adjust: {
    appToken: string
    eventTokens: {
      [key: string]: string
    }
  }
}

const configNode = document.getElementById("js_config")

if (!configNode) throw new Error("JS config script tag could not be found")

export default JSON.parse(configNode.innerHTML) as Config
