import config from "core/config"
import getDefaultProperties from "./default_properties"
import sendPayload from "./send_payload"
import navigationType from "./navigation_type"
import { PageProperties } from "."

let trackedPage: Record<string, unknown> | null = null

export default function page(name?: string, properties: PageProperties = {}) {
  if (trackedPage && !properties.force) return

  const args = Object.assign(
    { name: name || null },
    properties,
    getDefaultProperties(),
    { navigation_type: navigationType() }
  )

  trackedPage = { args }

  sendPayload(config.routes.trackingPagePath, args)
}
