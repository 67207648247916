import { compact } from "utils/array"
import { tap } from "utils/object"

import Url from "domurl"

export const toCamelCase = (str: any): string =>
  String(str)
    .replace(/^\s+|\s+$/g, "")
    .replace(/[-_\s]+(.)?/g, (_match, c) => {
      return c ? c.toUpperCase() : ""
    })

export const toSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const capitalize = (str: any): string => {
  const input = String(str)
  return input.charAt(0).toUpperCase() + input.slice(1)
}

export const classify = (str: any): string =>
  capitalize(toCamelCase(String(str).replace(/[\W_]/g, " ")).replace(/\s/g, ""))

export const isBlank = (str: any): boolean =>
  str ? /^\s*$/.test(String(str)) : true

export const viewNamespace = (str: any): string =>
  compact(str.split("-").map(classify)).join(".")

const HTML_ESCAPE_MAP: { [key: string]: string } = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;",
  "/": "&#x2F;",
  "`": "&#x60;",
  "=": "&#x3D;",
}

export const htmlEscape = (str: any): string =>
  String(str).replace(/[&<>"'`=/]/g, (s) => HTML_ESCAPE_MAP[s])

export const urlWithParams = (
  input: string,
  params: Record<string, unknown>
): string =>
  tap(new Url(input), (url) => Object.assign(url.query, params)).toString()

// https://gist.github.com/jed/982883
export const uuid = () => {
  // 10000000-1000-4000-80000000-100000000000
  // replace  zeroes, ones, and eights with random hex digits
  return ("" + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,
    (placeholder) => {
      const n = parseInt(placeholder, 10)
      // a random number from 0 to 15
      // unless b is 8, in which case a random number from 8 to 11
      // in hexadecimal
      return (n ^ ((Math.random() * 16) >> (n / 4))).toString(16)
    }
  )
}
