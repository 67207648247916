// Custom function to get browser timezone offset in ISO format (e.g. +02:00) without using date-fns
// We can't define it in the utils/date file because it won't tree-shake properly date-fns
export const getBrowserISOTimezoneOffset = () => {
  const tzo = -new Date().getTimezoneOffset()
  const sign = tzo >= 0 ? "+" : "-"
  const pad = function (num: number) {
    return (num < 10 ? "0" : "") + num
  }
  return (
    sign + pad(Math.floor(Math.abs(tzo) / 60)) + ":" + pad(Math.abs(tzo) % 60)
  )
}
