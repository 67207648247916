/* global Calendly */
import tracking from "core/tracking"
import { query } from "@drivy/dom-query"

export default () => {
  const el = query(".js_owner_onboarding_panel")

  if (el) {
    tracking.event("announcement_viewed", {
      label: `owner_onboarding_${el.dataset.trackingName}_display.${el.dataset.locale}`,
    })

    el.addEventListener("click", (e: Event) => {
      e.preventDefault()
      /** Calendly is injected by an external script globally */
      Calendly.showPopupWidget(el.dataset.calendlyUrl)
      tracking.event("announcement_viewed", {
        label: `owner_onboarding_${el.dataset.trackingName}_click.${el.dataset.locale}`,
      })
    })
  }
}
