import config from "core/config"
import Cookies from "js-cookie"
import { uuid } from "utils/string"

const ANONYMOUS_KEY = "dt_anonymous_id"
const EXPIRY_IN_DAYS = 365

export function init() {
  set(get() || uuid())
}

export function set(anonymous_id: string) {
  Cookies.set(ANONYMOUS_KEY, anonymous_id, {
    expires: EXPIRY_IN_DAYS,
    domain: config.cookieDomain,
  })
}

export function get(): string | undefined {
  // previous cookies was stored with encoded double quotes
  return Cookies.get(ANONYMOUS_KEY)?.replace(/"/g, "")
}
