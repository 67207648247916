import Url from "domurl"

import config from "core/config"

import { get as getAnonymousId } from "./anonymous_id"
import getUTMParams from "./utm_params"
import { getBrowserISOTimezoneOffset } from "utils/timezone"
import { isBlank } from "utils/string"

function getBrowserWidth() {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  )
}

function getBrowserHeight() {
  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  )
}

export function cleanupLanguage(language) {
  const match = language.match(/^[-a-zA-Z0-9]+/)
  if (match && match[0].match(/^[a-zA-Z]{1,3}(-[a-zA-Z0-9]{1,8})*$/)) {
    return match[0]
  } else {
    return ""
  }
}

function getBrowserLanguage() {
  interface CustomNavigator extends Navigator {
    userLanguage?: string
    browserLanguage?: string
    systemLanguage?: string
  }

  const navigator = window.navigator as CustomNavigator
  const language =
    navigator.language ||
    navigator.userLanguage ||
    navigator.browserLanguage ||
    navigator.systemLanguage ||
    ""
  return cleanupLanguage(language)
}

export default function getDefaultProperties() {
  const utmParams = getUTMParams(window.location.href)
  const params = {
    title: document.title,
    url: window.location.href,
    path: new Url(window.location.href).path,
    page_search: window.location.search,
    referrer: document.referrer,
    user_agent: window.navigator.userAgent,
    browser_width: getBrowserWidth(),
    browser_height: getBrowserHeight(),
    browser_language: getBrowserLanguage(),
    browser_timezone_offset: getBrowserISOTimezoneOffset(),
    campaign_medium: utmParams.utm_medium,
    campaign_source: utmParams.utm_source,
    campaign_name: utmParams.utm_campaign,
    campaign_content: utmParams.utm_content,
    marin_keyword_id: utmParams.mkwid,
    anonymous_id: getAnonymousId(),
  }

  const commonProperties: { [key: string]: string | boolean } = Object.keys(
    params
  ).reduce(
    (acc, key) =>
      isBlank(params[key]) ? acc : Object.assign(acc, { [key]: params[key] }),
    {}
  )

  if (config.experiment) {
    const experiment = config.experiment
    commonProperties[`experiment_name_${experiment.slot.ua}`] = experiment.name
    commonProperties[`experiment_value_${experiment.slot.ua}`] =
      experiment.value
  }

  return commonProperties
}
