import horizontalScrollList from "components/horizontal_scroll_list"
import initOrderForm from "./order_form"
import { query } from "@drivy/dom-query"
import { initNewsSnippets } from "./news_snippets"
import { handleUberAccountLinkingModals } from "views/web/uber_account_linking"
import { initHomepageTracking } from "./tracking"
import { initUsOnboarding } from "./us_onboarding"
import { initGrowlComponent } from "components/growl"
import { initImageSwappers } from "components/image_swapper"
import { initInAppMessages } from "core/inAppMessages/init"

export const initHomepage = () => {
  initHomepageTracking()
  initInAppMessages()

  const search_form = query(".js_search_form")
  const promoteSearchWithoutDates =
    search_form != null && !!search_form.dataset.promoteSearchWithoutDates

  initOrderForm({
    promoteSearchWithDates: !promoteSearchWithoutDates,
    searchButtonSelector: ".js_search_form_submit",
  })

  const placesList = document.querySelector(".js_horizontal_scroll_list_places")
  if (placesList) horizontalScrollList(placesList)

  initNewsSnippets()
  initUsOnboarding()
  handleUberAccountLinkingModals()
  initImageSwappers()
  initGrowlComponent()
}
