import { HEADER_KEY, TOKEN } from "utils/csrf"
import { toParams } from "utils/url"

export const fetchGetOptions = {
  credentials: "same-origin",
  headers: {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
} as const

export const fetchPutOptions = {
  method: "PUT",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    [HEADER_KEY]: TOKEN || "",
  },
} as const

export const fetchPostOptions = {
  method: "POST",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    [HEADER_KEY]: TOKEN || "",
  },
} as const

export const fetchFormUrlEncodedOptions = {
  method: "POST",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "X-Requested-With": "XMLHttpRequest",
    [HEADER_KEY]: TOKEN || "",
  },
} as const

export const fetchPatchOptions = {
  method: "PATCH",
  credentials: "same-origin",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    [HEADER_KEY]: TOKEN || "",
  },
} as const

export const fetchDeleteOptions = {
  method: "DELETE",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    [HEADER_KEY]: TOKEN || "",
  },
} as const

export function fetchPut(url: string, dataToPut: Record<string, unknown>) {
  return fetch(url, fetchPutConfiguration(dataToPut))
}

export function fetchPutConfiguration(dataToPut: Record<string, unknown>) {
  return {
    ...fetchPutOptions,
    body: JSON.stringify(dataToPut),
  }
}

export function fetchPostConfiguration(dataToPost: Record<string, unknown>) {
  return {
    ...fetchPostOptions,
    body: JSON.stringify(dataToPost),
  }
}

export function fetchPost(url: string, dataToPost: Record<string, unknown>) {
  return fetch(url, fetchPostConfiguration(dataToPost))
}

export function fetchPatchConfiguration(dataToPatch: Record<string, unknown>) {
  return {
    ...fetchPatchOptions,
    body: JSON.stringify(dataToPatch),
  }
}

export function fetchPatch(url: string, dataToPatch: Record<string, unknown>) {
  return fetch(url, fetchPatchConfiguration(dataToPatch))
}

export function fetchDeleteConfiguration(data?: Record<string, unknown>) {
  return {
    ...fetchDeleteOptions,
    body: data != null ? JSON.stringify(data) : null,
  }
}

export function fetchDelete(url: string, data?: Record<string, unknown>) {
  return fetch(url, fetchDeleteConfiguration(data))
}

export function fetchForm(form: HTMLFormElement) {
  const formData = extractFormData(form)
  const serializedData = encodeURI(
    Object.keys(formData)
      .map((key) => `${key}=${formData[key]}`)
      .join("&")
  )

  return fetch(form.getAttribute("action")!, {
    ...fetchFormUrlEncodedOptions,
    body: serializedData,
  })
}

export function fetchGet(
  url: string,
  parameters: Record<string, any>,
  options: Record<string, unknown> = {},
  sortParams = true
) {
  const parameterSeparator = url.includes("?") ? "&" : "?"
  return fetch(
    `${url}${parameterSeparator}${toParams(parameters, { sort: sortParams })}`,
    {
      ...fetchGetOptions,
      ...options,
    }
  )
}

export function isFetchCancel(error: Error) {
  return error.name && error.name === "AbortError"
}

function extractFormData(form: HTMLFormElement) {
  // form inputs under a hidden block should not be taken into account
  const hiddenFormInputs = Array.from(
    form.querySelectorAll<HTMLElement>(
      "[hidden] input, [hidden] textarea,  [hidden] select"
    )
  )

  const formData = Array.from(
    form.querySelectorAll<HTMLInputElement>(
      "input:not([hidden]):not([disabled]), textarea:not([hidden]):not([disabled], select:not([hidden]):not([disabled]"
    )
  ).reduce((acc, input) => {
    if (!hiddenFormInputs.includes(input) && input.type !== "submit") {
      if (input.type === "checkox") {
        if (input.checked) {
          if (!acc[input.name]) {
            acc[input.name] = []
          }
          acc[input.name].push(input.value)
        }
      } else if (input.type === "radio") {
        if (input.checked) {
          acc[input.name] = input.value
        }
      } else {
        acc[input.name] = input.value
      }
    }

    return acc
  }, {})

  return formData
}
