import "core/polyfills"
// SEO-friendly and self-initializing lazyloader for images
import "lazysizes/plugins/unveilhooks/ls.unveilhooks"
import "lazysizes"

import config from "core/config"
import { mark, measure } from "core/performance"
import initDashboardNavigation from "./views/dashboard_navigation"
import Header from "./views/header"
import Footer from "./views/footer"
import handleDeferredTracking from "drivy_bootstrap/handle_deferred_tracking"
import { query, queryAll } from "@drivy/dom-query"

import "sentry/index"
import tracking, {
  initTrackingBeforeConsent,
  initThirdPartyTracking,
} from "core/tracking"
import initCookieConsent from "core/cookieConsent"

import initCallouts from "components/callouts"
import initOwnerOnboardingPanel from "components/drivy/owner_onboarding_panel"
import { notifyError, createError } from "core/errors"
import { screenSizeBreakpoint, isStorageAvailable } from "utils/dom"
import initObfuscatedLink from "components/obfuscated_link"
import { registerLazyImages } from "core/lazyload"
import { handleDeferredFlashMessages } from "utils/flashMessages"

initTrackingBeforeConsent()
initCallouts()

query("#js_news")?.addEventListener("callout:close", async (e: Event) => {
  if (!e.target) return
  const hideAnnouncementsUrl = (e.target as HTMLElement).dataset[
    "hide-announcements-path"
  ]
  if (!hideAnnouncementsUrl) return
  try {
    const resp = await fetch(hideAnnouncementsUrl)
    if (!resp.ok) {
      throw new Error("Failing fetching hide announcements: " + resp.statusText)
    }
    return await resp.json()
  } catch (err) {
    notifyError(
      new (createError("JS News hide announcements error"))(
        "Failed fetch call: " + err.message
      )
    )
  }
})

initOwnerOnboardingPanel()

/** Focus delegation */
queryAll(".js_focus_trigger").on("click", (e: Event) => {
  e.stopPropagation()
  if (e.currentTarget) {
    query(
      `.js_focus_target[data-focus='${
        (e.currentTarget as HTMLElement).dataset.for
      }']`
    )?.dispatchEvent(new Event("focus"))
  }
})
queryAll(".js_stop_propagation").on("click", (e: Event) => {
  e.stopPropagation()
})

window.addEventListener("resize", () => {
  queryAll("[data-form-layout]").forEach(function (el) {
    const formLayoutClass = (el as HTMLElement).dataset["form-layout"] || ""
    if (screenSizeBreakpoint() === "xs") {
      el.classList.remove(formLayoutClass)
      el.classList.add("vertical_form")
    } else {
      el.classList.remove("vertical_form")
      el.classList.add(formLayoutClass)
    }
  })
})

queryAll(".js_drk_lnk").forEach(initObfuscatedLink)

// Toggle extra infos links
queryAll(".js_toggle_extra_infos").on("click", (e: Event) => {
  const target = (e.currentTarget as HTMLElement).dataset.target
  target && query(target)?.classList.toggle("hide")
})

const initExperimentTracking = () => {
  const { experiment } = config

  if (!experiment || !isStorageAvailable("sessionStorage")) {
    return
  }

  const sessionStorageKey = `segmentExperimentVariation-${experiment.name}`
  const alreadyTrackedInSession =
    sessionStorage.getItem(sessionStorageKey) !== null

  if (alreadyTrackedInSession) return

  sessionStorage.setItem(sessionStorageKey, "true")

  return tracking.event("Experiment Viewed", {
    experiment_name: experiment.name,
    variation_name: experiment.value,
    slot_name: experiment.slot.ua,
  })
}

const onDocumentLoaded = async () => {
  await initCookieConsent(async () => {
    await initThirdPartyTracking()
    if (config.tracking) {
      handleDeferredTracking(config.tracking)
    }
    // If the page tracking is not handled by the deferred tracking,
    // we have to track at minimum
    tracking.page()
  })

  mark("mark_start_init")

  initExperimentTracking()

  Header()
  Footer()

  initDashboardNavigation()

  mark("mark_end_init")
  measure("measure_init", "mark_start_init", "mark_end_init")

  handleDeferredFlashMessages()

  registerLazyImages()
}

document.addEventListener("DOMContentLoaded", onDocumentLoaded, { once: true })
