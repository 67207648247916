import { queryStrict } from "@drivy/dom-query"

/**
 * We isolate this function in a dedicated module to allow tree shaking while
 * using async module with Webpack 4 (in app/assets/javascripts/views/web/search/initAsyncSearchForm.ts)
 * https://github.com/webpack/webpack.js.org/issues/2684
 * This should be fixed and merge back to search_hero_form when migrated to
 * Webpack 5
 * https://github.com/webpack/webpack.js.org/blob/d643f6778cf5433c170b19625e6394aa0e566986/src/content/blog/2020-10-10-webpack-5-release.md#general-tree-shaking-improvements
 */

function unlockForm(searchForm: HTMLElement) {
  searchForm.toggleAttribute("disabled", false)

  const submitSearchForm = queryStrict(".js_search_form_submit")
  submitSearchForm.toggleAttribute("disabled", false)
}

export default unlockForm
