import { query } from "@drivy/dom-query"

export function initGrowlComponent() {
  const growl = query(".cobalt-growl")
  growl?.query(".cobalt-growl__close")?.on("click", () => {
    growl.dataset.targetUrl &&
      fetch(String(growl.dataset.targetUrl), {
        method: growl.dataset.targetMethod || "GET",
      })
    growl.parentElement?.removeChild(growl)
  })
}
