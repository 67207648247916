import * as Sentry from "@sentry/browser"
import * as Integrations from "@sentry/integrations"

import referrerEventProcessor from "./referrer"
import jqueryVersionEventProcessor from "./jquery-version"
import outdatedBrowsersEventProcessor from "./outdated-browsers"
import customErrorFingerprintEventProcessor from "./custom-error-fingerprint"
import ignoreIfHandledEventProcessor from "./ignore-handled"
import config from "core/config"

const networkErrors = [
  // Aborted network calls for Firefox
  "The operation was aborted.",
  "Request aborted",
  // Aborted network calls for other browsers
  // 'Failed to fetch' is also a CORS error when using fetch
  // but we should be able to detect CORS issues elsewhere than in Sentry
  "Failed to fetch",
  "NetworkError when attempting to fetch resource.",
  "The user aborted a request",
  "Fetch is aborted",
  "Network Error",
  "annulé",
  "cancelado",
  "cancelled",
  "Abgebrochen",
  "annullato",
  "geannuleerd",
  "avbrutt",
  "скасовано",
]

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    release: config.release == null ? undefined : config.release,
    environment: config.env,
    ignoreErrors: [
      // Error generated by a bug in auto-fill library from browser
      // https://github.com/getsentry/sentry/issues/5267
      /Blocked a frame with origin/,
      // https://sentry.io/drivy/js-production/issues/302518952/activity/
      "Cannot read property 'closingEls' of undefined",
      // https://sentry.io/drivy/js-production/issues/302438454/
      "__firefox__",
      // https://sentry.io/drivy/js-production/issues/713528052
      "androidInterface",
      // Email link Microsoft Outlook crawler compatibility error
      // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
      ...networkErrors,
    ],
    denyUrls: [
      // https://sentry.io/drivy/js-production/issues/345509286/
      /\/affs\?/,
    ],
    allowUrls: config.jsErrorsWhitelistedUrls || undefined,
    integrations: [
      new Integrations.Dedupe(),
      new Integrations.ExtraErrorData(),
    ],
  })

  Sentry.configureScope((scope) => {
    scope.addEventProcessor(ignoreIfHandledEventProcessor)
    scope.addEventProcessor(outdatedBrowsersEventProcessor)
    scope.addEventProcessor(referrerEventProcessor)
    scope.addEventProcessor(jqueryVersionEventProcessor)
    scope.addEventProcessor(customErrorFingerprintEventProcessor)

    scope.setTag("action", config.action)
    scope.setTag("country", config.country)
    scope.setTag("locale", config.locale)
  })

  // Expose Sentry so other bundles can use it without importing it
  // Necessary for core/error
  ;(window as any).Sentry = {
    captureException: Sentry.captureException,
    addBreadcrumb: Sentry.addBreadcrumb,
  }
}
