import config from "core/config"

// Global variables set by Bing script
declare const window: Window &
  typeof globalThis & {
    UET: any
    uetq: any
    isBingInitialized: boolean
  }

const trackEvent = ({ event, params }) => {
  window.uetq.push("event", event, params)
}

const trackingEventsQueue: {
  event: string
  params: unknown
}[] = []

const enqueueEvent = ({ event, params }) => {
  trackingEventsQueue.push({ event, params })
}

const trackEnqueuedEvents = () => {
  while (trackingEventsQueue.length) {
    trackEvent(trackingEventsQueue.pop()!)
  }
}

export const initBing = async () => {
  if (!config?.bingId) return
  if (window.isBingInitialized) return

  /* eslint-disable */
  {
    /*
      Microsoft Bing Ads provides the following code to set up the UET tag tracking code.
      For more information, please refer to https://help.ads.microsoft.com/#apex/ads/en/56686/2
    */
    ;(function (w, d, t, r, u) {
      var f, n, i
      ;(w[u] = w[u] || []),
        (f = function () {
          var o = { ti: config.bingId }
          // @ts-expect-error: Bing provided obfuscated code
          ;(o.q = w[u]), (w[u] = new window.UET(o)), w[u].push("pageLoad")
        }),
        (n = d.createElement(t)),
        (n.src = r),
        (n.async = 1),
        (n.onload = n.onreadystatechange =
          function () {
            var s = this.readyState
            ;(s && s !== "loaded" && s !== "complete") ||
              (f(), (n.onload = n.onreadystatechange = null))
          }),
        (i = d.getElementsByTagName(t)[0]),
        i.parentNode.insertBefore(n, i)
    })(window, document, "script", "//bat.bing.com/bat.js", "uetq")
  }
  /* eslint-enable */
  window.isBingInitialized = true
  trackEnqueuedEvents()
}

export const trackConversionWithBing = (event, params) => {
  window.uetq ? trackEvent({ event, params }) : enqueueEvent({ event, params })
}
