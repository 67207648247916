declare const window: Window &
  typeof globalThis & {
    gtag: any
  }

export function trackEventWithGoogleAnalytics(event, params = {}) {
  if (!window.gtag) return

  window.gtag("event", event, params)
}
