import config from "core/config"
import trackPage from "./page"
import trackEvent from "./event"
import { init as initAnonymousId } from "./anonymous_id"

export interface Properties {
  [key: string]: string | number | boolean | null | undefined
}

export type PageProperties = Properties & {
  force?: boolean
}

function event(name: string, properties: Properties = {}) {
  if (config.trackingEnabled !== true) return
  trackEvent(name, properties)
}

function page(name?: string, properties: PageProperties = {}) {
  if (config.trackingEnabled !== true) return
  trackPage(name, properties)
}

export function init() {
  initAnonymousId()
}

export default {
  event,
  page,
}
