import { isObject } from "./object"

type Options = { sort: boolean }

type UrlGetParametersType = Record<
  string,
  | Parameters<typeof encodeURIComponent>[0]
  | Parameters<typeof encodeURIComponent>[0][]
>

export const toParams = (
  obj: UrlGetParametersType,
  { sort }: Options = { sort: true }
): string => {
  const params = Object.entries(obj).reduce((acc: string[], [key, val]) => {
    if (val != null) {
      if (Array.isArray(val)) {
        val.forEach((value) => {
          if (isObject(value)) {
            Object.entries(value).forEach(([subKey, subVal]) => {
              acc.push(
                `${key}[][${subKey}]=${encodeURIComponent(subVal as any)}`
              )
            })
          } else {
            acc.push(`${key}[]=${encodeURIComponent(value)}`)
          }
        })
      } else {
        acc.push(`${key}=${encodeURIComponent(val)}`)
      }
    }
    return acc
  }, [])

  if (sort) params.sort()
  return params.join("&")
}

export const isValidURIContent = (value: string): boolean => {
  try {
    decodeURI(value)
    return true
  } catch (e) {
    return false
  }
}
