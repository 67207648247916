import { EventProcessor } from "@sentry/types"

interface CustomWindow extends Window {
  callPhantom: any
  _phantom: any
}

const global = window as CustomWindow & typeof globalThis

// https://github.com/Modernizr/Modernizr/blob/5eea7e2a213edc9e83a47b6414d0250468d83471/feature-detects/dom/dataset.js
const supportsDataset = () => {
  const div = document.createElement("div")
  div.setAttribute("data-a-b", "c")
  return !!(div.dataset && div.dataset.aB === "c")
}

const supportsRecentEnoughFeatures = () => {
  return (
    !!global.requestAnimationFrame &&
    supportsDataset() &&
    document.documentElement &&
    document.documentElement.classList !== null
  )
}

const isUABlacklisted = (ua: string) => {
  return (
    /IEMobile\/(9|10)/i.test(ua) ||
    /PhantomJS/i.test(ua) ||
    global.callPhantom ||
    global._phantom ||
    /Bytespider/i.test(ua)
  )
}

const shouldDropEvent = () =>
  isUABlacklisted(global.navigator.userAgent) || !supportsRecentEnoughFeatures

const outdatedBrowsersEventProcessor: EventProcessor = (event) => {
  return shouldDropEvent() ? null : event
}

export default outdatedBrowsersEventProcessor
