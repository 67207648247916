import config from "core/config"

// Global variables set by FacebookPixel script
declare const window: Window &
  typeof globalThis & {
    fbq: any
    isFacebookPixelSet: boolean
  }

const trackEvent = ({ event, params }) => {
  const standardEvents = [
    "ViewContent",
    "Search",
    "InitiateCheckout",
    "Purchase",
    "CompleteRegistration",
  ]

  const type = standardEvents.includes(event) ? "track" : "trackCustom"
  window.fbq(type, event, params)
}

const trackingEventsQueue: { event: string; params: unknown }[] = []

const enqueueEvent = ({ event, params }) => {
  trackingEventsQueue.push({ event, params })
}

const trackEnqueuedEvents = () => {
  while (trackingEventsQueue.length) {
    trackEvent(trackingEventsQueue.pop()!)
  }
}

export async function initFacebookPixel() {
  if (!config?.facebookPixelId) return
  // already initialised
  if (window.isFacebookPixelSet) return

  // prettier-ignore
  /* eslint-disable*/
  {
    // @ts-expect-error: Facebook provided obfuscated code
    ;!function(f,b,e,v,n,t,s)
    // @ts-expect-error: Facebook provided obfuscated code
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    // @ts-expect-error: Facebook provided obfuscated code
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
    // @ts-expect-error: Facebook provided obfuscated code
    t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
  }
  /* eslint-enable */

  window.fbq("init", config?.facebookPixelId)
  window.isFacebookPixelSet = true
  window.fbq("track", "PageView")
  trackEnqueuedEvents()
}

export const trackConversionWithFacebook = (event, params = {}) => {
  window.fbq ? trackEvent({ event, params }) : enqueueEvent({ event, params })
}
