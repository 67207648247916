import { query } from "@drivy/dom-query"
import { initBrazeInAppMessages } from "./braze"
import config from "core/config"
import { registerOnAuthenticatedUserCallback } from "components/user_menu/utils"

export function initInAppMessages() {
  // in-app messages only for authenticated users
  registerOnAuthenticatedUserCallback((userInfos) => {
    if (userInfos.id) {
      const existingCobaltModal = query(".cobalt-modal")
      if (!existingCobaltModal) {
        initBrazeInAppMessages({
          currentUserId: `${userInfos.id}`,
          baseUrl: config.brazeWebSdk.baseUrl,
          apiKey: config.brazeWebSdk.apiKey,
        })
      }
    }
  })
}
