export function isMobile(): boolean {
  // Adapted from http://www.detectmobilebrowsers.com
  // Checks for iOs, Android, Blackberry, Opera Mini, and Windows mobile devices
  return /iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/.test(
    navigator.userAgent ||
      navigator.vendor ||
      (window as Window & { opera: any } & typeof globalThis).opera
  )
}

export function reloadPage(): void {
  document.location.reload()
}

export function isHistoryAPIAvailable(): boolean {
  // copied from Modernizr
  const ua = navigator.userAgent

  // We only want Android 2 and 4.0, stock browser, and not Chrome which identifies
  // itself as 'Mobile Safari' as well, nor Windows Phone (issue #1471).
  if (
    (ua.indexOf("Android 2.") !== -1 || ua.indexOf("Android 4.0") !== -1) &&
    ua.indexOf("Mobile Safari") !== -1 &&
    ua.indexOf("Chrome") === -1 &&
    ua.indexOf("Windows Phone") === -1 &&
    location.protocol !== "file:"
  ) {
    return false
  }

  // Return the regular check
  return window.history && "pushState" in window.history
}

export function isStorageAvailable(type: string): boolean {
  try {
    const storage = window[type]
    const key = "__storage_test__"

    storage.setItem(key, key)
    storage.removeItem(key)

    return true
  } catch (e) {
    return false
  }
}

export function screenSizeBreakpoint() {
  const windowWidth = window.innerWidth

  if (windowWidth < 768) return "xs"
  if (windowWidth < 992) return "sm"
  if (windowWidth < 1200) return "md"
  return "lg"
}

export function isMobileScreen() {
  const size = screenSizeBreakpoint()

  return size === "xs"
}

export function isSmallScreen() {
  return ["xs", "sm"].includes(screenSizeBreakpoint())
}

export function scrollToTop(el: any, speed = 400): Promise<void> {
  const EASING_FUNCTION = "easeOutQuart"

  // extract from jQueryUIeffects
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  $.easing[EASING_FUNCTION] = (_x, t, b, c, d) =>
    -c * ((t = t / d - 1) * t * t * t - 1) + b // eslint-disable-line no-param-reassign

  return new Promise((resolve) => {
    // eslint-disable-next-line jquery/no-animate
    $("html, body").animate(
      { scrollTop: $(el).offset()!.top - 5 },
      speed,
      EASING_FUNCTION,
      resolve
    )
  })
}

const easeOutQuart = (t: number) => 1 - --t * t * t * t // eslint-disable-line no-param-reassign

export function scrollToLeft(
  el: HTMLElement,
  to: number,
  duration: number
): Promise<void> {
  const farthestScrollPosition = el.scrollWidth - el.clientWidth
  const final = Math.max(Math.min(farthestScrollPosition, Math.round(to)), 0)

  const currentTime = () =>
    window.performance != null && "now" in window.performance
      ? performance.now()
      : new Date().getTime()

  return new Promise((resolve) => {
    const start = el.scrollLeft
    const startTime = currentTime()

    const scroll = () => {
      const now = currentTime()
      const time = Math.min(1, (now - startTime) / duration)
      const newLeft = easeOutQuart(time) * (final - start) + start

      el.scrollLeft = newLeft

      if (Math.ceil(el.scrollLeft) === final) {
        resolve()
      } else {
        requestAnimationFrame(scroll)
      }
    }

    scroll()
  })
}

// do not calculate it again until next page load
let scrollbarWidth: number | null = null
export function getScrollbarWidth() {
  if (scrollbarWidth != null) return scrollbarWidth

  // https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement("div")
  scrollDiv.className = "scrollbar_measure"

  const body = document.body

  if (body == null) {
    throw new Error("Scrollbar width can't be calculated as body is null")
  }

  body.appendChild(scrollDiv)
  scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  body.removeChild(scrollDiv)
  return scrollbarWidth
}

export const clientHeight = () =>
  Math.max(
    document.documentElement ? document.documentElement.clientHeight : 0,
    window.innerHeight || 0
  )

export function isVisible(el: HTMLElement): boolean {
  return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length)
}

export function isInViewport(element: HTMLElement) {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function isTopElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect()
  return rect.top >= 0
}

export function scrollToTopElementIfNotInView(el: HTMLElement, padding = 0) {
  if (!isMobile() && !isTopElementInViewport(el)) {
    window.scrollTo({
      behavior: "smooth",
      top: window.scrollY + el.getBoundingClientRect().top - padding,
    })
  }
}

export function loadCss(href: string): Promise<void> {
  return new Promise((resolve) => {
    const link = document.createElement("link")
    link.type = "text/css"
    link.rel = "stylesheet"
    link.href = href

    if (document.head == null)
      throw new Error("Cannot load CSS without a head element")

    document.head.appendChild(link)

    if (link.addEventListener) link.addEventListener("load", () => resolve())
  })
}

export function ensureInHorizontalView(
  container: HTMLElement,
  element: HTMLElement,
  duration = 0
): void {
  const contLeft = container.scrollLeft
  const contRight = contLeft + container.clientWidth

  const elemLeft = element.offsetLeft
  const elemRight = elemLeft + element.clientWidth

  let scrollBy = 0
  if (elemLeft < contLeft) scrollBy = -1 * (contLeft - elemLeft)
  else if (elemRight > contRight) scrollBy = elemRight - contRight

  if (scrollBy)
    scrollToLeft(container, container.scrollLeft + scrollBy, duration)
}

export const sumStylePropertiesValues = (
  element: HTMLElement,
  properties: string[]
): number =>
  properties.reduce(
    (acc: number, property: string) =>
      acc + parseFloat(getComputedStyle(element).getPropertyValue(property)),
    0
  )

export const getDocumentScrollPosition = () => {
  const scrollPosition =
    window.pageYOffset || document.documentElement.scrollTop

  return scrollPosition
}

export const getTopOffset = (el: HTMLElement) => {
  return el.getBoundingClientRect().top + getDocumentScrollPosition()
}

export const isEventOutsideElement = (element: HTMLElement, event) => {
  return !element.contains(event.target) && isVisible(element)
}

export const fadeInAnimate = (
  element: HTMLElement,
  animationDuration: number,
  applyCssDisplayProperty = false,
  onAnimationEnd?: () => void
) => {
  if (applyCssDisplayProperty) {
    element.style.display = "block"
  }
  element.style.opacity = "0"
  element.style.transition = `opacity ${animationDuration}ms`
  setTimeout(() => {
    element.style.opacity = "1"
    setTimeout(() => {
      onAnimationEnd && onAnimationEnd()
    }, animationDuration)
  }, 10) // to force first time transition
}

export const fadeOutAnimate = (
  element: HTMLElement,
  animationDuration: number,
  applyCssDisplayProperty = false,
  onAnimationEnd?: () => void
) => {
  element.style.opacity = "1"
  element.style.transition = `opacity ${animationDuration}ms`
  element.style.opacity = "0"
  setTimeout(() => {
    if (applyCssDisplayProperty) {
      element.style.display = "none"
    }
    onAnimationEnd && onAnimationEnd()
  }, animationDuration)
}

export const getParsedElementData = <DataType extends Record<string, unknown>>(
  element: HTMLElement | null,
  key: string
) => {
  if (!element) return
  try {
    return JSON.parse(element.dataset[key] || "") as DataType
  } catch (err) {
    return element.dataset[key] as string | undefined
  }
}

// Allow to use element from event.target but ensure it is a Node before
export function ensureElementIsNode(e: EventTarget | null): asserts e is Node {
  if (!e || !("nodeType" in e)) {
    throw new Error(`Node expected`)
  }
}
