interface BrazeInAppMessagesInterface {
  currentUserId: string
  baseUrl: string | null
  apiKey: string | null
}

export async function initBrazeInAppMessages({
  currentUserId,
  baseUrl,
  apiKey,
}: BrazeInAppMessagesInterface) {
  if (apiKey?.length && baseUrl?.length) {
    const braze = await import("@braze/web-sdk")

    // initialize the SDK
    braze.initialize(apiKey, {
      baseUrl,
    })

    // optionally show all in-app messages without custom handling
    braze.automaticallyShowInAppMessages()

    // optionally set the current user's external ID before starting a new session
    // you can also call `changeUser` later in the session after the user logs in
    braze.changeUser(currentUserId)

    // `openSession` should be called last - after `changeUser` and `automaticallyShowInAppMessages`
    braze.openSession()
  }
}
