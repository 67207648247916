import { createError, notifyError } from "core/errors"
import config from "core/config"
import { isStorageAvailable } from "./dom"

export const handleDeferredFlashMessages = async () => {
  const AsyncFlashMessageError = createError("AsyncFlashMessageError")
  if (config.flashMessage?.message && config.flashMessage?.status) {
    try {
      const createFlashItem = (await import("components/flash/flash"))
        .createFlashItem
      createFlashItem(config.flashMessage.message, config.flashMessage.status)
    } catch (err) {
      notifyError(new AsyncFlashMessageError(err.message))
    }
  }
  if (
    isStorageAvailable("sessionStorage") &&
    sessionStorage.getItem("message")
  ) {
    const message = JSON.parse(sessionStorage.getItem("message") || "null")
    if (message.message && message.status) {
      try {
        const createFlashItem = (await import("components/flash/flash"))
          .createFlashItem
        createFlashItem(message.message, message.status)
      } catch (err) {
        notifyError(new AsyncFlashMessageError(err.message))
      }
      sessionStorage.removeItem("message")
    }
  }
}

export const setSessionMessage = (message: string, status: string) => {
  if (!isStorageAvailable("sessionStorage")) return
  sessionStorage.setItem("message", JSON.stringify({ message, status }))
}
